import React from 'react';
import { Link } from 'react-router-dom';
import shape1 from '../img/icon/shape1.svg'
import shape2 from '../img/icon/shape2.svg'
import Footer from './Footer';
import Header from './Header';
import bg1 from '../img/icon/bg1.png';
import trustpilot from '../img/icon/trustpilot.png';
import google from '../img/icon/google.png';
import { Colors } from 'chart.js';
import Call_to_action_1 from './Call_to_action1';

function Home() {
  return (
    <div id="main-wrapper">
	<Header/>
    
    <div className="clearfix"></div>
    
    <div className="image-cover hero-header pb-0" style={{ background: '#ecf3f1 url(https://shreethemes.net/jobstock-landing-2.2/jobstock/assets/img/slider-6.jpg) no-repeat' }}>
    <div className="bnnr-slack-elior-thumb d-sm-none d-lg-block d-none z-1"><img src={bg1} className="img-fluid" alt="" /></div>
        <div className="position-absolute top-0 end-0 z-0">
            <img src={shape1} alt="SVG" className='opacity-25' width="450"/>
        </div>
        <div className="position-absolute top-0 start-0 me-10 z-0">
            <img src={shape2} alt="SVG" className='opacity-25' width="350"/>
        </div>
        <div className="container position-relative z-2">
            <div className="row">
                <div className="col-xl-7 col-lg-8 col-md-12">
                    <div className="simple-search-wrap mb-5">
                        <div className="hero-search-2">
                            <h1 className="mb-4">Great Job Opportunity<br/>You Deserve</h1>
                            <div className="full-search-2 mt-5">
                                <div className="hero-search-content">
                                    
                                    <div className="row classic-search-box m-0 gx-2">
                                            
                                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                            <div className="form-group briod">
                                                <div className="input-with-icon">
                                                    <input type="text" className="form-control" placeholder="Skills, Designations, Keyword"/>
                                                    <i className="fa-solid fa-magnifying-glass text-primary"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <div className="input-with-icon">
                                                    <select className="form-control">
                                                    <option value="1">Job Category</option>
                                                    <option value="2">Accounting & Finance</option>
                                                    <option value="3">Automotive Jobs</option>
                                                    <option value="4">Business Services</option>
                                                    <option value="5">Education Training</option>
                                                    <option value="6">Software Application</option>
                                                    <option value="7">Restaurant & Food</option>
                                                    <option value="8">Healthcare</option>
                                                    <option value="9">Transportation</option>
                                                    <option value="10">Telecommunications</option>
                                                </select>
                                                <i className="fa-solid fa-briefcase text-primary"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                            <div className="fliox-search-wiop">
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary full-width">Find Job</button>
                                                </div>
                                            </div>
                                        </div>
                                                
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="estrio-plocks-wraps">
                        <div className="estrio-plocks-single">
                            <div className="estrio-single-icon">
                                <img src={trustpilot} className="img-fluid" alt=""/>	
                            </div>
                            <div className="estrio-single-caption">
                                <div className="estrio-caption-title"><p>Trustpilot<span>4.9</span></p></div>
                                <div className="estrio-caption-stars">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div className="estrio-plocks-single">
                            <div className="estrio-single-icon">
                                <img src={google} className="img-fluid" alt=""/>	
                            </div>
                            <div className="estrio-single-caption">
                                <div className="estrio-caption-title"><p>Google Plus<span>4.8</span></p></div>
                                <div className="estrio-caption-stars">
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="col-xl-5 col-lg-4 col-md-12">
                    <div className="bnnr-slack-thumb d-lg-none d-sm-block mt-sm-5">
                        <img src="assets/img/new-prn.png" className="img-fluid" alt=""/>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <section className="primary-bg-dark min bg-theme">
        <div className="container">
            
            <div className="row justify-content-center mb-2">
                <div className="col-lg-7 col-md-10 text-center">
                    <div className="sec-heading center mb-4">
                        <h5 className="text-light opacity-75 fw-medium">Our Top Partnership Company</h5>
                    </div>
                </div>
            </div>
            
            <div className="row align-items-center justify-content-center row-cols-xl-5 row-cols-lg-5 row-cols-md-3 row-cols-3 gx-3 gy-3">
                <div className="col">
                    <figure className="single-brand thumb-figure">
                        <img  className="img-fluid" alt=""/>
                    </figure>
                </div>
                
                <div className="col">
                    <figure className="single-brand thumb-figure">
                        <img  className="img-fluid" alt=""/>
                    </figure>
                </div>
                
                <div className="col">
                    <figure className="single-brand thumb-figure">
                        <img className="img-fluid" alt=""/>
                    </figure>
                </div>
                
                <div className="col">
                    <figure className="single-brand thumb-figure">
                        <img  className="img-fluid" alt=""/>
                    </figure>
                </div>
                
                <div className="col">
                    <figure className="single-brand thumb-figure">
                        <img className="img-fluid" alt=""/>
                    </figure>
                </div>
            </div>
            
        </div>
    </section>
    <div className="clearfix"></div>

    
    
    <section>
        <div className="container">
        
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10 text-center">
                    <div className="sec-heading center">
                        <h2>Hire Talents & Experts</h2>
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p>
                    </div>
                </div>
            </div>
        
            <div className="row justify-content-center gx-4 gy-4">
            
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-1.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.6</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Barbara T. Jean</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>Sr. Web Designer</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>70/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>5 Years exp.</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-2.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.7</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Linda D. Strong</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>Front-End Developer</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>50/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>3 Years exp.</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-3.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.5</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Marylyn A. Jefferson</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>Shopify Expert</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>65/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>4 Years exp.</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-4.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.6</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Edward K. Buckler</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>WordPress Developer</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>60/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>3 Years exp.</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-5.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.8</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Howard S. Lopez</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>Magento Developer</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>75/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>6 Years exp.</div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-6.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.9</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Frank T. Reeves</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>Sr. UI/UX Designer</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>80/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>7 Years exp.</div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-7.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.6</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Joseph R. Marshall</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>Laravel Developer</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>65/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>4.5 Years exp.</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="jbs-grid-usrs-block border">
                        <div className="jbs-grid-usrs-thumb">
                            <div className="jbs-grid-yuo">
                                <a href="candidate-detail.html"><figure><img src="assets/img/team-8.jpg" className="img-fluid circle" alt=""/></figure></a>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-caption">
                            <div className="jbs-kioyer">
                                <div className="jbs-kioyer-groups">
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star active"></span>
                                    <span className="fa-solid fa-star"></span>
                                    <span className="aal-reveis">4.7</span>
                                </div>
                            </div>
                            <div className="jbs-tiosk">
                                <h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Kr. Dhananjay Preet</a></h4>
                                <div className="jbs-tiosk-subtitle"><span>Sr. Front-End Developer</span></div>
                            </div>
                        </div>
                        <div className="jbs-grid-usrs-info">
                            <div className="jbs-info-ico-style bold">
                                <div className="jbs-single-y1 style-2"><span><i className="fa-solid fa-sack-dollar"></i></span>45/H</div>
                                <div className="jbs-single-y1 style-3"><span><i className="fa-solid fa-coins"></i></span>8 Years exp.</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
            
        </div>		
    </section>
    
    
    
    <section className="gray-simple">
        <div className="container">
        
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10 text-center">
                    <div className="sec-heading center">
                        <h2>Explore Job in NewYork</h2>
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p>
                    </div>
                </div>
            </div>
        
            <div className="row g-xl-3 g-lg-3 g-4">
            
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="jbs-location-infortment">
                        <div className="jbs-location-infortment-thumb p-3">
                            <a href="job-search.html" className="jobstock-location-figure">
                                <img src="assets/img/c-1.png" className="img-fluid rounded" alt=""/>
                            </a>
                        </div>
                        <div className="jbs-location-infortment-content">
                            <h4>California, USA</h4>
                            <span className="resy-98 fw-medium text-primary">307+ Jobs</span>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="jbs-location-infortment">
                        <div className="jbs-location-infortment-thumb p-3">
                            <a href="job-search.html" className="jobstock-location-figure">
                                <img src="assets/img/c-2.png" className="img-fluid rounded" alt=""/>
                            </a>
                        </div>
                        <div className="jbs-location-infortment-content">
                            <h4>Denver City, USA</h4>
                            <span className="resy-98 fw-medium text-primary">102+ Jobs</span>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="jbs-location-infortment">
                        <div className="jbs-location-infortment-thumb p-3">
                            <a href="job-search.html" className="jobstock-location-figure">
                                <img src="assets/img/c-3.png" className="img-fluid rounded" alt=""/>
                            </a>
                        </div>
                        <div className="jbs-location-infortment-content">
                            <h4>Washington, USA</h4>
                            <span className="resy-98 fw-medium text-primary">200+ Jobs</span>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="jbs-location-infortment">
                        <div className="jbs-location-infortment-thumb p-3">
                            <a href="job-search.html" className="jobstock-location-figure">
                                <img src="assets/img/c-4.png" className="img-fluid rounded" alt=""/>
                            </a>
                        </div>
                        <div className="jbs-location-infortment-content">
                            <h4>Liverpool, UK</h4>
                            <span className="resy-98 fw-medium text-primary">150+ Jobs</span>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>		
    </section>
    
    
    <section>
        <div className="container">
            
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10 text-center">
                    <div className="sec-heading center">
                        <h2>Featured Jobs</h2>
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p>
                    </div>
                </div>
            </div>
            
            <div className="row justify-content-center gx-xl-3 gx-3 gy-4">
            
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="featured-text">Featured</span>
                        </div>
                        <div className="brows-job-type"><span className="full-time">Full Time</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-3.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Shopify<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">Technical Content Writer</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>London, UK</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$80K - 110K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="featured-text">Featured</span>
                            <span className="urgent">Urgent</span>
                        </div>
                        <div className="brows-job-type"><span className="enternship">Enternship</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-4.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Deezroo<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">Front-end Developer</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>Canada, USA</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$50K - 70K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="urgent">Urgent</span>
                        </div>
                        <div className="brows-job-type"><span className="part-time">Part Time</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-5.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Photoshop<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">Expert Team Leader</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>Denver, USA</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$80K - 90K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="featured-text">Featured</span>
                        </div>
                        <div className="brows-job-type"><span className="part-time">Part Time</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-6.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Firefox<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">New Shopify Developer</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>California, USA</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$90K - 100K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="featured-text">Featured</span>
                            <span className="urgent">Urgent</span>
                        </div>
                        <div className="brows-job-type"><span className="full-time">Full Time</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-7.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Air BNB<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">Sr. Magento Developer</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>Canada, USA</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$80K - 110K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="urgent">Urgent</span>
                        </div>
                        <div className="brows-job-type"><span className="enternship">Enternship</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-8.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Snapchat<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">Sr. Code Ignetor Developer</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>London, UK</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$60K - 90K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="featured-text">Featured</span>
                        </div>
                        <div className="brows-job-type"><span className="freelanc">Freelancer</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-9.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Dribbble<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">Java & Python Developer</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>New York, USA</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$85K - 90K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>
                
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <div className="job-instructor-layout border">
                        <div className="left-tags-capt">
                            <span className="featured-text">Featured</span>
                            <span className="urgent">Urgent</span>
                        </div>
                        <div className="brows-job-type"><span className="full-time">Full Time</span></div>
                        <div className="job-instructor-thumb">
                            <a href="job-detail.html"><img src="assets/img/l-10.png" className="img-fluid" alt=""/></a>
                        </div>
                        <div className="job-instructor-content">
                            <div className="jbs-job-employer-wrap"><span>Skype<span></span></span></div>
                            <h4 className="instructor-title"><a href="job-detail.html">Sr. UI/UX Designer</a></h4>
                            <div className="text-center text-sm-muted">
                                <span><i className="fa-solid fa-location-dot me-2"></i>Denver, USA</span>
                            </div>
                            <div className="jbs-grid-job-edrs-group center mt-2">
                                <span>HTML</span>
                                <span>CSS3</span>
                                <span>Bootstrap</span>
                                <span>Redux</span>
                            </div>
                        </div>
                        <div className="jbs-grid-job-apply-btns px-3 py-3">
                            <div className="jbs-btn-groups">
                                <div className="jbs-sng-blux"><div className="jbs-grid-package-title smalls"><h5>$70K - 95K<span>\Year</span></h5></div></div>
                                <div className="jbs-sng-blux"><a href="JavaScript:Void(0);" className="btn btn-md btn-light-primary px-4">Quick Apply</a></div>
                            </div>
                        </div>
                    </div>	
                </div>						
                
            </div>
            
        </div>
    </section>
    
    
    <section className="p-0">
        <div className="container-fluid px-0">
            <div className="row gx-0">
            
                <div className="col-xl-6 col-lg-6 col-md-12 bg-dark">
                    <div className="hired-box-slack">
                        <div className="hired-box-caption">
                            <h2 className="text-light">Hire talents & experts for your web development</h2>
                            <p className="text-light fw-light fs-5 opacity-75">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo</p>
                        </div>
                        <div className="hired-box-btns">
                            <Link>
                            <button className='btn btn-primary w-100'>Hire Us</button>
                            </Link>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-12 bg-secondary">
                    <div className="hired-box-slack">
                        <div className="hired-box-caption">
                            <h2 className="text-light">We Are Expert In Web design and development</h2>
                            <p className="text-light fw-light fs-5 opacity-75">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo</p>
                        </div>
                        <div className="hired-box-btns">
                        <Link>
                            <button className='btn btn-dark w-100'>Join Us</button>
                            </Link>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    
    
    <section>
        <div className="container">
        
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10 text-center">
                    <div className="sec-heading center">
                        <h2>Trending News By Job Stock</h2>
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores</p>
                    </div>
                </div>
            </div>
            
            <div className="row g-xl-3 g-lg-3 g-md-3 g-3">
                
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <div className="jobstock-grid-blog">
                        <div className="jobstock-grid-blog-thumb">
                            <img src="assets/img/blog-1.jpg" className="img-fluid" alt=""/>
                        </div>
                        <div className="jobstock-grid-blog-body">
                            <div className="jobstock-grid-body-header">
                                <div className="jobstock-grid-posted bg-primary"><span>10 Jul 2022</span></div>
                                <div className="jobstock-grid-title"><h4><a href="blog-detail.html">How To Work with jobstock Agency</a></h4></div>
                            </div>
                            <div className="jobstock-grid-body-middle">
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat</p>
                            </div>
                            <div className="jobstock-grid-body-footer">
                                <a href="blog-detail.html" className="btn btn-blog-link">Continue Reading</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <div className="jobstock-grid-blog">
                        <div className="jobstock-grid-blog-thumb">
                            <img src="assets/img/blog-2.jpg" className="img-fluid" alt=""/>
                        </div>
                        <div className="jobstock-grid-blog-body">
                            <div className="jobstock-grid-body-header">
                                <div className="jobstock-grid-posted bg-primary"><span>17 Jan 2023</span></div>
                                <div className="jobstock-grid-title"><h4><a href="blog-detail.html">How To Work with jobstock Agency</a></h4></div>
                            </div>
                            <div className="jobstock-grid-body-middle">
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat</p>
                            </div>
                            <div className="jobstock-grid-body-footer">
                                <a href="blog-detail.html" className="btn btn-blog-link">Continue Reading</a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <div className="jobstock-grid-blog">
                        <div className="jobstock-grid-blog-thumb">
                            <img src="assets/img/blog-3.jpg" className="img-fluid" alt=""/>
                        </div>
                        <div className="jobstock-grid-blog-body">
                            <div className="jobstock-grid-body-header">
                                <div className="jobstock-grid-posted bg-primary"><span>26 Feb 2023</span></div>
                                <div className="jobstock-grid-title"><h4><a href="blog-detail.html">How To Work with jobstock Agency</a></h4></div>
                            </div>
                            <div className="jobstock-grid-body-middle">
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat</p>
                            </div>
                            <div className="jobstock-grid-body-footer">
                                <a href="blog-detail.html" className="btn btn-blog-link">Continue Reading</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>		
    </section>
    
    
    <Call_to_action_1/>
                
  <Footer/>

</div>
  );
}

export default Home;

       
