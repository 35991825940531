import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Dashboard_Nav from './User_dashboard_nav';
function Logout() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <div className="dashboard-wrap bg-light">
				<Dashboard_Nav/>
				
				
                
                		
				
			</div>
                

</div>
  );
}

export default Logout;

       
