import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import EM_nav_dashboard from './Dashboard_nav';
function EM_Logout() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
    <div className="dashboard-wrap bg-light">
				<a className="mobNavigation" data-bs-toggle="collapse" href="employer-dashboard.html#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
					<i className="fas fa-bars mr-2"></i>Dashboard Navigation
				</a>
				 <EM_nav_dashboard/>
				
							
				
			</div>
                
  		

</div>
  );
}

export default EM_Logout;

       
