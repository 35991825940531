
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Applied_job from './components/Applied_job';
import Change_password from './components/Change_password';
import Company_page from './components/company_page';
import Dashboard from './components/Dashboard';
import EM_Applicants from './components/Employer/Applicants';
import EM_dashboard from './components/Employer/Dashboard';
import EM_Job from './components/Employer/job';
import EM_Logout from './components/Employer/Logout';
import EM_Post_Job from './components/Employer/Post_jobs';
import EM_Profile from './components/Employer/Profile';
import EM_Short_Candidates from './components/Employer/Shortlisted_candidates';
import EM_Signup from './components/Employer/Signup';
import EM_Update_Password from './components/Employer/Update_password';
import Home from './components/Home';
import Job from './components/Job';
import Job_Apply from './components/Job_Apply';
import Logout from './components/Logout';
import Privacy_policy from './components/Privacy_policy';
import Profile from './components/Profile';
import Resume from './components/resume';
import Signup from './components/Signup';

function App() {
  return (
    <Router>
{ /*     <Header />
*/ }      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/job" element={<Job />} />
        <Route path="/job_apply" element={<Job_Apply />} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/resume" element={<Resume/>} />
        <Route path="/applied" element={<Applied_job/>} />
        <Route path="/change-password" element={<Change_password/>} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="/company" element={<Company_page/>} />
        <Route path="/privacy-policy" element={<Privacy_policy/>} />
        <Route path="/employer/dashboard" element={<EM_dashboard/>} />
        <Route path="/employer/profile" element={<EM_Profile/>} />
        <Route path="/employer/job" element={<EM_Job/>} />
        <Route path="/employer/post-job" element={<EM_Post_Job/>} />
        <Route path="/employer/applicants" element={<EM_Applicants/>} />
        <Route path="/employer/shortlisted-candidates" element={<EM_Short_Candidates/>} />
        <Route path="/employer/update-password" element={<EM_Update_Password/>} />
        <Route path="/employer/logout" element={<EM_Logout/>} />
        <Route path="/employer/signup" element={<EM_Signup/>} />
        <Route path="/employer/applicants" element={<EM_Applicants/>} />
      </Routes>
    </Router>
  );
}

export default App;
