import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
function Job() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
    
			<section className="gray-simple">
			
				<div className="container">
					
					<div className="row">
					
						<div className="col-lg-4 col-md-12 col-sm-12">
							
							<div className="bg-white rounded mb-3">							
							
								<div className="sidebar_header d-flex align-items-center justify-content-between px-4 py-3 br-bottom">
									<h4 className="fs-bold fs-5 mb-0">Search Filter</h4>
									<div className="ssh-header">
										<a href="javascript:void(0);" className="clear_all ft-medium text-muted">Clear All</a>
										<a href="grid-style-1.html#search_open" data-bs-toggle="collapse" aria-expanded="false" role="button" className="collapsed _filter-ico ml-2"><i className="fa-solid fa-filter"></i></a>
									</div>
								</div>
								
								<div className="sidebar-widgets collapse miz_show" id="search_open" data-bs-parent="#search_open">
									
									<div className="search-inner">
										
										<div className="filter-search-box px-4 pt-3">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Search by keywords..."/>
											</div>
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Location, Zip.."/>
											</div>
										</div>
										
										<div className="filter_wraps">
											
											<div className="single_search_boxed px-4 pt-0 br-bottom">
												<div className="widget-boxed-header">
													<h4>
														<a href="grid-style-1.html#categories" className="ft-medium fs-md" data-bs-toggle="collapse" aria-expanded="true" role="button">Job Categories</a>
													</h4>
													
												</div>
												<div className="widget-boxed-body collapse show" id="categories" data-bs-parent="#categories">
													<div className="side-list no-border">
														<div className="single_filter_card">
															<div className="card-body p-0">
																<div className="inner_widget_link">
																	<ul className="no-ul-list filter-list">
																		<li>
																			<input id="a1" className="form-check-input" name="ADA" type="checkbox" checked=""/>
																			<label for="a1" className="form-check-label">IT Computers (62)</label>
																		</li>
																		<li>
																			<input id="aa1" className="form-check-input" name="ADA" type="checkbox"/>
																			<label for="aa1" className="form-check-label">Web Design (31)</label>
																		</li>
																		<li>
																			<input id="aa2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="aa2" className="form-check-label">Web development (20)</label>
																		</li>
																		<li>
																			<input id="aa3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="aa3" className="form-check-label">SEO Services (43)</label>
																		</li>
																		<li>
																			<input id="a2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="a2" className="form-check-label">Financial Service (16)</label>
																		</li>
																		<li>
																			<input id="a3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="a3" className="form-check-label">Art, Design, Media (22)</label>
																		</li>
																		<li>
																			<input id="a4" className="form-check-input" name="Mother" type="checkbox"/>
																			<label for="a4" className="form-check-label">Coach & Education (21)</label>
																		</li>
																		<li>
																			<input id="a5" className="form-check-input" name="Outdoor" type="checkbox"/>
																			<label for="a5" className="form-check-label">Apps Developements (17)</label>
																		</li>
																		<li>
																			<input id="ab1" className="form-check-input" name="ADA" type="checkbox"/>
																			<label for="ab1" className="form-check-label">IOS Development (12)</label>
																		</li>
																		<li>
																			<input id="ab2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="ab2" className="form-check-label">Android Development (04)</label>
																		</li>
																		<li>
																			<input id="a6" className="form-check-input" name="Pet" type="checkbox"/>
																			<label for="a6" className="form-check-label">Writing & Translation (04)</label>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											
											<div className="single_search_boxed px-4 pt-0 br-bottom">
												<div className="widget-boxed-header">
													<h4>
														<a href="grid-style-1.html#locations" data-bs-toggle="collapse" aria-expanded="false" role="button" className="ft-medium fs-md collapsed">Job Locations</a>
													</h4>
													
												</div>
												<div className="widget-boxed-body collapse" id="locations" data-bs-parent="#locations">
													<div className="side-list no-border">
														<div className="single_filter_card">
															<div className="card-body p-0">
																<div className="inner_widget_link">
																	<ul className="no-ul-list filter-list">
																		<li>
																			<input id="b1" className="form-check-input" name="ADA" type="checkbox" checked=""/>
																			<label for="b1" className="form-check-label">Australia (21)</label>
																		</li>
																		<li>
																			<input id="b2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="b2" className="form-check-label">New Zeland (12)</label>
																		</li>
																		<li>
																			<input id="b3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="b3" className="form-check-label">United Kingdom (21)</label>
																		</li>
																		<li>
																			<input id="ac1" className="form-check-input" name="ADA" type="checkbox"/>
																			<label for="ac1" className="form-check-label">London (06)</label>
																		</li>
																		<li>
																			<input id="ac2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="ac2" className="form-check-label">Manchester (07)</label>
																		</li>
																		<li>
																			<input id="ac3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="ac3" className="form-check-label">Birmingham (08)</label>
																		</li>
																		<li>
																			<input id="b4" className="form-check-input" name="Mother" type="checkbox"/>
																			<label for="b4" className="form-check-label">United State (04)</label>
																		</li>
																		<li>
																			<input id="ad1" className="form-check-input" name="ADA" type="checkbox"/>
																			<label for="ad1" className="form-check-label">New York (32)</label>
																		</li>
																		<li>
																			<input id="ad2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="ad2" className="form-check-label">Washington (42)</label>
																		</li>
																		<li>
																			<input id="ad3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="ad3" className="form-check-label">Los Angeles (22)</label>
																		</li>
																		<li>
																			<input id="b5" className="form-check-input" name="Outdoor" type="checkbox"/>
																			<label for="b5" className="form-check-label">India (15)</label>
																		</li>
																		<li>
																			<input id="b6" className="form-check-input" name="Pet" type="checkbox"/>
																			<label for="b6" className="form-check-label">Singapore (09)</label>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											
											<div className="single_search_boxed px-4 pt-0 br-bottom">
												<div className="widget-boxed-header">
													<h4>
														<a href="grid-style-1.html#skills" data-bs-toggle="collapse" aria-expanded="false" role="button" className="ft-medium fs-md collapsed">Skills</a>
													</h4>
													
												</div>
												<div className="widget-boxed-body collapse" id="skills" data-bs-parent="#skills">
													<div className="side-list no-border">
														<div className="single_filter_card">
															<div className="card-body p-0">
																<div className="inner_widget_link">
																	<ul className="no-ul-list filter-list">
																		<li>
																			<input id="c1" className="form-check-input" name="ADA" type="checkbox" checked=""/>
																			<label for="c1" className="form-check-label">Administrative (15)</label>
																		</li>
																		<li>
																			<input id="c2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="c2" className="form-check-label">iPhone & Android (33)</label>
																		</li>
																		<li>
																			<input id="c3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="c3" className="form-check-label">Java & AJAX (32)</label>
																		</li>
																		<li>
																			<input id="c4" className="form-check-input" name="Mother" type="checkbox"/>
																			<label for="c4" className="form-check-label">Account Manager (21)</label>
																		</li>
																		<li>
																			<input id="c5" className="form-check-input" name="Outdoor" type="checkbox"/>
																			<label for="c5" className="form-check-label">WordPress (32)</label>
																		</li>
																		<li>
																			<input id="c6" className="form-check-input" name="Pet" type="checkbox"/>
																			<label for="c6" className="form-check-label">Magento (42)</label>
																		</li>
																		<li>
																			<input id="c7" className="form-check-input" name="Beauty" type="checkbox"/>
																			<label for="c7" className="form-check-label">Shopify (12)</label>
																		</li>
																		<li>
																			<input id="c8" className="form-check-input" name="Bike" type="checkbox"/>
																			<label for="c8" className="form-check-label">PHP Script (08)</label>
																		</li>
																		<li>
																			<input id="c9" className="form-check-input" name="Phone" type="checkbox"/>
																			<label for="c9" className="form-check-label">Drupal (32)</label>
																		</li>
																		<li>
																			<input id="c11" className="form-check-input" name="Private" type="checkbox"/>
																			<label for="c11" className="form-check-label">Joomla (50)</label>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											
											<div className="single_search_boxed px-4 pt-0">
												<div className="widget-boxed-header">
													<h4>
														<a href="grid-style-1.html#radiusmiles" data-bs-toggle="collapse" aria-expanded="false" role="button" className="ft-medium fs-md collapsed">Radius in Miles</a>
													</h4>
													
												</div>
												<div className="widget-boxed-body collapse show" id="radiusmiles" data-bs-parent="#radiusmiles">
													<div className="side-list no-border">
														<div className="single_filter_card">
															<div className="card-body p-0">
																<div className="rg-slider">
																	 <input type="text" className="js-range-slider" name="my_range" value=""/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											
											<div className="single_search_boxed px-4 pt-0 br-bottom">
												<div className="widget-boxed-header">
													<h4>
														<a href="grid-style-1.html#experience" data-bs-toggle="collapse" aria-expanded="false" role="button" className="ft-medium fs-md collapsed">Experience</a>
													</h4>
													
												</div>
												<div className="widget-boxed-body collapse" id="experience" data-bs-parent="#experience">
													<div className="side-list no-border">
														<div className="single_filter_card">
															<div className="card-body p-0">
																<div className="inner_widget_link">
																	<ul className="no-ul-list filter-list">
																		<li>
																			<input id="d1" className="form-check-input" name="ADA" type="checkbox" checked=""/>
																			<label for="d1" className="form-check-label">Beginner (54)</label>
																		</li>
																		<li>
																			<input id="d2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="d2" className="form-check-label">1+ Year (32)</label>
																		</li>
																		<li>
																			<input id="d3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="d3" className="form-check-label">2 Year (09)</label>
																		</li>
																		<li>
																			<input id="d4" className="form-check-input" name="Mother" type="checkbox"/>
																			<label for="d4" className="form-check-label">3+ Year (16)</label>
																		</li>
																		<li>
																			<input id="d5" className="form-check-input" name="Outdoor" type="checkbox"/>
																			<label for="d5" className="form-check-label">4+ Year (17)</label>
																		</li>
																		<li>
																			<input id="d6" className="form-check-input" name="Pet" type="checkbox"/>
																			<label for="d6" className="form-check-label">5+ Year (22)</label>
																		</li>
																		<li>
																			<input id="d7" className="form-check-input" name="Beauty" type="checkbox"/>
																			<label for="d7" className="form-check-label">10+ Year (32)</label>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											
											<div className="single_search_boxed px-4 pt-0 br-bottom">
												<div className="widget-boxed-header">
													<h4>
														<a href="grid-style-1.html#jbtypes" data-bs-toggle="collapse" aria-expanded="false" role="button" className="ft-medium fs-md collapsed">Job Type</a>
													</h4>
													
												</div>
												<div className="widget-boxed-body collapse" id="jbtypes" data-bs-parent="#jbtypes">
													<div className="side-list no-border">
														<div className="single_filter_card">
															<div className="card-body p-0">
																<div className="inner_widget_link">
																	<ul className="no-ul-list filter-list">
																		<li>
																			<input id="e2" className="form-check-input" name="jtype" type="radio"/>
																			<label for="e2" className="form-check-label">Full time</label>
																		</li>
																		<li>
																			<input id="e3" className="form-check-input" name="jtype" type="radio"/>
																			<label for="e3" className="form-check-label">Part Time</label>
																		</li>
																		<li>
																			<input id="e4" className="form-check-input" name="jtype" type="radio" checked=""/>
																			<label for="e4" className="form-check-label">Contract Base</label>
																		</li>
																		<li>
																			<input id="e5" className="form-check-input" name="jtype" type="radio"/>
																			<label for="e5" className="form-check-label">Internship</label>
																		</li>
																		<li>
																			<input id="e6" className="form-check-input" name="jtype" type="radio"/>
																			<label for="e6" className="form-check-label">Regular</label>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											
											<div className="single_search_boxed px-4 pt-0 br-bottom">
												<div className="widget-boxed-header">
													<h4>
														<a href="grid-style-1.html#jblevel" data-bs-toggle="collapse" aria-expanded="false" role="button" className="ft-medium fs-md collapsed">Job Level</a>
													</h4>
													
												</div>
												<div className="widget-boxed-body collapse" id="jblevel" data-bs-parent="#jblevel">
													<div className="side-list no-border">
														<div className="single_filter_card">
															<div className="card-body p-0">
																<div className="inner_widget_link">
																	<ul className="no-ul-list filter-list">
																		<li>
																			<input id="f1" className="form-check-input" name="ADA" type="checkbox" checked=""/>
																			<label for="f1" className="form-check-label">Team Leader</label>
																		</li>
																		<li>
																			<input id="f2" className="form-check-input" name="Parking" type="checkbox"/>
																			<label for="f2" className="form-check-label">Manager</label>
																		</li>
																		<li>
																			<input id="f3" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="f3" className="form-check-label">Junior</label>
																		</li>
																		<li>
																			<input id="f4" className="form-check-input" name="Coffee" type="checkbox"/>
																			<label for="f4" className="form-check-label">Senior</label>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											
										</div>
										
										<div className="form-group filter_button pt-3 pb-3 px-4">
											<button type="submit" className="btn btn-primary full-width">Search job</button>
										</div>
									</div>							
								</div>
							</div>
							
                            <div className="alert-jbemail-box bg-cover" style={{ background: '#016551 url(assets/img/alert-bg.png) no-repeat' }} overlay="0" >								<div className="alert-bxr-wrap">
									<div className="alert-bxr-captions mb-3">
										<h3 className="text-light">Get The Latest Jobs Right Into Your Inbox!</h3>
										<p className="text-light opacity-75">We just want your email address!</p>
									</div>
									<div className="alert-bxr-forms">
										<form>
											<div className="newsltr-form">
												<input type="text" className="form-control" placeholder="Enter Your email"/>
												<button type="button" className="btn btn-subscribe bg-dark">Subscribe</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							
						</div>
						
						<div className="col-lg-8 col-md-12 col-sm-12">
						
							<div className="row justify-content-center mb-4">
								<div className="col-lg-12 col-md-12">
									<div className="item-shorting-box">
										<div className="item-shorting clearfix">
											<div className="left-column"><h4 className="m-sm-0 mb-2">Showing 1 - 10 of 20 Results</h4></div>
										</div>
										<div className="item-shorting-box-right">
											<div className="shorting-by me-2 ">
												<select className='form-control'>
													<option value="0">Short by (Default)</option>
													<option value="1">Short by (Featured)</option>
													<option value="2">Short by (Urgent)</option>
													<option value="3">Short by (Post Date)</option>
												</select>
											</div>
											<div className="shorting-by ">
												<select className="form-control">
													<option value="0">10 Per Page</option>
													<option value="1">20 Per Page</option>
													<option value="2">50 Per Page</option>
													<option value="3">10 Per Page</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div className="row justify-content-center gx-xl-3 gx-3 gy-4">
					
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="enternship">Enternship</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-1.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Jr. PHP Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$5K - $8K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">6 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="freelanc">Freelancer</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-2.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Exp. Project manager</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$6K - $10K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">4 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="part-time">Part Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-3.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Sr. WordPress Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$5K - $8K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">3 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
										</div>
										<div className="brows-job-type"><span className="full-time">Full Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-4.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Jr. Laravel Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$4.2K - $6K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">2 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="freelanc">Freelancer</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-5.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Sr. UI/UX Designer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$4K - $5.5K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">5 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="part-time">Part Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-6.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Java & Python Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$2K - $4K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">4 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="full-time">FullTime</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-7.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Sr. Code Ignetor Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$5K - $6K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">3 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
										</div>
										<div className="brows-job-type"><span className="enternship">Enternship</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-8.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Sr. Magento Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$3.2K - $5K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">5 Open</span>
											</div>
										</div>
									</div>	
								</div>

								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="part-time">Part Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-9.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Data Project Manager</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$10K - $15K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">1 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
										</div>
										<div className="brows-job-type"><span className="full-time">Full Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-10.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">HTML5 To WordPress Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$8K - $10K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">4 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="part-time">Part Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-11.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Jr. Content Writer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$4K - $5.5K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">2 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="full-time">Full Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-12.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Sr. Figma Designer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$6K - $8K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">3 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="enternship">Enternship</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-1.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Jr. PHP Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$5K - $8K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">6 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="freelanc">Freelancer</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-2.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Exp. Project manager</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$6K - $10K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">4 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
								<div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
									<div className="job-instructor-layout border">
										<div className="left-tags-capt">
											<span className="featured-text">Featured</span>
											<span className="urgent">Urgent</span>
										</div>
										<div className="brows-job-type"><span className="part-time">Part Time</span></div>
										<div className="job-instructor-thumb">
											<a href="job-detail.html"><img src="assets/img/l-3.png" className="img-fluid" alt=""/></a>
										</div>
										<div className="job-instructor-content">
											<h4 className="instructor-title"><a href="job-detail.html">Sr. WordPress Developer</a></h4>
											<div className="instructor-skills">
												CSS3, HTML5, Javascript, Bootstrap, Jquery
											</div>
										</div>
										<div className="job-instructor-footer">
											<div className="instructor-students">
												<h5 className="instructor-scount">$5K - $8K</h5>
											</div>
											<div className="instructor-corses">
												<span className="c-counting">3 Open</span>
											</div>
										</div>
									</div>	
								</div>
								
							</div>
							
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12">
									<nav aria-label="Page navigation example">
										  <ul className="pagination">
											<li className="page-item">
											  <a className="page-link" href="JavaScript:Void(0);" aria-label="Previous">
												<span aria-hidden="true">&laquo;</span>
											  </a>
											</li>
											<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">1</a></li>
											<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">2</a></li>
											<li className="page-item active"><a className="page-link" href="JavaScript:Void(0);">3</a></li>
											<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">4</a></li>
											<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">5</a></li>
											<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">6</a></li>
											<li className="page-item">
											  <a className="page-link" href="JavaScript:Void(0);" aria-label="Next">
												<span aria-hidden="true">&raquo;</span>
											  </a>
											</li>
										  </ul>
									</nav>
								</div>
							</div>
					
						</div>
						
					</div>
				</div>		
			</section>
            <section className="bg-cover primary-bg-dark" style={{ background: 'url(assets/img/footer-bg-dark.png) no-repeat' }} >				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-7 col-lg-10 col-md-12 col-sm-12">
							
							<div className="call-action-wrap">
								<div className="sec-heading center">
									<h2 className="lh-base mb-3 text-light">Find The Perfect Job<br/>on Job Stock That is Superb For You</h2>
									<p className="fs-6 text-light">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias</p>
								</div>
								<div className="call-action-buttons mt-3">
									<a href="JavaScript:Void(0);" className="btn btn-lg btn-dark fw-medium px-xl-5 px-lg-4 me-2">Upload resume</a>
									<a href="JavaScript:Void(0);" className="btn btn-lg btn-whites fw-medium px-xl-5 px-lg-4 text-primary">Join Our Team</a>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>
                
    <Footer/>

</div>
  );
}

export default Job;

       
