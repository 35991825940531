import React from 'react';
import { Link } from 'react-router-dom';
function Dashboard_Nav() {
  return (
       <>
       <a className="mobNavigation" data-bs-toggle="collapse" href="candidate-dashboard.html#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
					<i className="fas fa-bars mr-2"></i>Dashboard Navigation
				</a>
				 <div className="collapse" id="MobNav">
					<div className="dashboard-nav">
						<div className="dash-user-blocks pt-5">
							<div className="jbs-grid-usrs-thumb">
								<div className="jbs-grid-yuo">
									<a href="candidate-detail.html"><figure><img src="https://media.licdn.com/dms/image/D5603AQEDHWLHQq6DVQ/profile-displayphoto-shrink_400_400/0/1714630865108?e=1722470400&v=beta&t=sCo57pJFK98Mru92QDw19UAKDg3vNBcxYwhQGUa-H_M" className="img-fluid circle" alt=""/></figure></a>
								</div>
							</div>
							<div className="jbs-grid-usrs-caption mb-3">
								<div className="jbs-kioyer">
									<div className="jbs-kioyer-groups">
										<span className="fa-solid fa-star active"></span>
										<span className="fa-solid fa-star active"></span>
										<span className="fa-solid fa-star active"></span>
										<span className="fa-solid fa-star active"></span>
										<span className="fa-solid fa-star"></span>
										<span className="aal-reveis">4.7</span>
									</div>
								</div>
								<div className="jbs-tiosk">
									<h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Linda D. Strong</a></h4>
									<div className="jbs-tiosk-subtitle"><span>Front-End Developer</span></div>
								</div>
							</div>
						</div>
						<div className="dashboard-inner">
							<ul data-submenu-title="">
								<li className="active"><Link to="../dashboard"><i className="fa-solid fa-gauge-high me-2"></i>User Dashboard</Link></li>
								<li><Link to="../profile"><i className="fa-regular fa-user me-2"></i>My Profile </Link></li>
								<li><Link to="../resume"><i className="fa-solid fa-file-pdf me-2"></i>My Resumes</Link></li>
								<li><Link to="../applied"><i className="fa-regular fa-paper-plane me-2"></i>Applied jobs</Link></li>
								<li><Link to="../change-password"><i className="fa-solid fa-unlock-keyhole me-2"></i>Change Password</Link></li>
								<li><Link to="../logout"><i className="fa-solid fa-power-off me-2"></i>Log Out</Link></li>
							</ul>
						</div>					
					</div>
				</div>
					
       </>
    );
}

export default Dashboard_Nav;
