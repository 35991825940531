import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import EM_nav_dashboard from './Dashboard_nav';
function EM_Job() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
    <div className="dashboard-wrap bg-light">
				<a className="mobNavigation" data-bs-toggle="collapse" href="employer-dashboard.html#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
					<i className="fas fa-bars mr-2"></i>Dashboard Navigation
				</a>
				 <EM_nav_dashboard/>
				
                 <div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Manage jobs</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="employer-jobs.html#">Employer</a></li>
										<li className="breadcrumb-item text-muted"><a href="employer-jobs.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="employer-jobs.html#" className="text-primary">My Jobs</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
						
						
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<div className="_mp-inner-content elior">
											<div className="_mp-inner-first">
												<div className="search-inline">
													<input type="text" className="form-control" placeholder="Job title, Keywords etc."/>
													<button type="button" className="btn btn-primary">Search</button>
												</div>
											</div>
											<div className="_mp_inner-last">
												<div className="item-shorting-box-right">
													<div className="shorting-by me-2 small">
														<select>
															<option value="0">Short by (Default)</option>
															<option value="1">Short by (Featured)</option>
															<option value="2">Short by (Urgent)</option>
															<option value="3">Short by (Post Date)</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="row mb-3">
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="duster-flex-row align-items-center d-flex justify-content-between">
													<div className="duster-flex-first">
														<h6 className="mb-0">Upgrade Package - 10 Days Left</h6>
													</div>
													<div className="duster-flex-end">
														<ul className="nav nav-pills nav-fill gap-2 p-1 small gray-simple rounded" id="pillNav2" role="tablist">
															<li className="nav-item" role="presentation">
																<button className="nav-link active rounded" id="alls" data-bs-toggle="tab" type="button" role="tab" aria-selected="true">All: 138</button>
															</li>
															<li className="nav-item" role="presentation">
																<button className="nav-link rounded" id="actives" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">Active: 122</button>
															</li>
															<li className="nav-item" role="presentation">
																<button className="nav-link rounded" id="expireds" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">Axpired: 16</button>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										
										<div className="row justify-content-start gx-3 gy-4">
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-1.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Tripadvisor</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Technical Content Writer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-warning label">244 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-pencil"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-2.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Pintrest</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">New Shopify Developer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-info label">110 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-clock"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-3.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Shopify</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. Magento Developer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-info label">320 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-pencil"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-4.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Dreezoo</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. Code Ignetor Developer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-info label">170 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-pencil"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-5.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Photoshop</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Java & Python Developer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-info label">190 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-pencil"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-6.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Firefox</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. UI/UX Designer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-danger label">Expired</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-clock"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-7.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Airbnb</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Jr. Laravel Developer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-info label">205 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-pencil"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-8.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Snapchat</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. WordPress Developer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-info label">320 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-pencil"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-9.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Dribbble</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Exp. Project manager</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-danger label">Expired</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-clock"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-10.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-employer-wrap"><span>Skype</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Jr. PHP Developer</a></h4></div>
															</div>
														</div>
														<div className="jbs-list-applied-users">
															<span className="text-sm-muted text-light bg-info label">150 Applicants</span>
														</div>
														<div className="jbs-list-postedinfo">
															<p className="m-0 text-sm-muted"><strong>Posted:</strong><span className="text-success">17 Apr 2023</span></p>
															<p className="m-0 text-sm-muted"><strong>Expired:</strong><span className="text-danger">12 Jun 2024</span></p>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3"><i className="fa-solid fa-pencil"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
										</div>
							
									</div>
								</div>
							</div>	
						</div>
	
					</div>
					
					
		
				</div>				
				
			</div>

            

</div>
  );
}

export default EM_Job;

       
