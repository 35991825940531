import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import EM_nav_dashboard from './Dashboard_nav';
function EM_Post_Job() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
    <div className="dashboard-wrap bg-light">
				<a className="mobNavigation" data-bs-toggle="collapse" href="employer-dashboard.html#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
					<i className="fas fa-bars mr-2"></i>Dashboard Navigation
				</a>
				 <EM_nav_dashboard/>
				
                 <div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Post Jobs</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="employer-submit-job.html#">Employer</a></li>
										<li className="breadcrumb-item text-muted"><a href="employer-submit-job.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="employer-submit-job.html#" className="text-primary">Post Jobs</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
						
						<div className="card">
							<div className="card-header">
								<h4>Basic Detail</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
									
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="dash-prf-start justify-content-start align-items-start mb-2">
												<div className="dash-prf-start-upper mb-2">
													<div className="dash-prf-start-thumb">
														<figure className="mb-0"><img src="assets/img/l-5.png" className="img-fluid rounded" alt=""/></figure>
													</div>
												</div>
												<div className="dash-prf-start-bottom">
													<div className="upload-btn-wrapper small">
														<button className="btn">Company Logo</button>
														<input type="file" name="myfile"/>
													</div>
												</div>
											</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="form-group">
												<label>Job Title</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="form-group">
												<label>Job Description</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Job Category</label>
												<div className="select-ops">
													<select>
														<option value="1">Web & Application</option>
														<option value="2">Banking Services</option>
														<option value="3">UI/UX Design</option>
														<option value="4">IOS/App Application</option>
														<option value="5">Education</option>
													</select>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Job Type</label>
												<div className="select-ops">
													<select>
														<option value="1">Full Time</option>
														<option value="2">Part Time</option>
														<option value="3">Freelance</option>
														<option value="4">Internship</option>
														<option value="5">Contract</option>
													</select>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Job Lavel</label>
												<div className="select-ops">
													<select>
														<option value="1">Team Leader</option>
														<option value="2">Manager</option>
														<option value="3">Junior</option>
														<option value="4">Senior</option>
														<option value="5">Other</option>
													</select>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Experience</label>
												<div className="select-ops">
													<select>
														<option value="1">Fresher</option>
														<option value="2">1+ Years</option>
														<option value="3">2+ Years</option>
														<option value="4">3+ Years</option>
														<option value="5">4+ Years</option>
														<option value="6">5+ Years</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Qualification</label>
												<div className="select-ops">
													<select>
														<option value="1">10th Class</option>
														<option value="2">12th Class</option>
														<option value="3">Bachelore Degree</option>
														<option value="4">Master Degree</option>
														<option value="5">Post Graduate</option>
														<option value="6">Any Other</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Gender</label>
												<div className="select-ops">
													<select>
														<option value="1">Male</option>
														<option value="2">Female</option>
														<option value="3">Other</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Min. Sallary</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Max. Sallary</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Job Expired Date</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Job Fee Type</label>
												<div className="select-ops">
													<select>
														<option value="1">Normal</option>
														<option value="2">Premium</option>
														<option value="3">Urgent</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="form-group">
												<label>Skills, Use Commas for saperate</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="form-group">
												<label>Permanent Address</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="form-group">
												<label>Temporary Address</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Country</label>
												<div className="select-ops">
													<select>
														<option value="1">India</option>
														<option value="2">United State</option>
														<option value="3">United kingdom</option>
														<option value="4">Austrailia</option>
														<option value="5">Russia</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>State/City</label>
												<div className="select-ops">
													<select>
														<option value="1">California</option>
														<option value="2">Denver</option>
														<option value="3">New York</option>
														<option value="4">Canada</option>
														<option value="5">Poland</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Zip Code</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Video URL</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Latitude</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>longitude</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										
										
									</div> 
								</form>
							</div>
						</div>
						
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<button type="submit" className="btn ft--medium btn-primary">Save & Preview</button>
							</div>	
						</div>
	
					</div>
					
					
		
				</div>	
				
			</div>

            

</div>
  );
}

export default EM_Post_Job;

       
