import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
function Job_Apply() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <section className="bg-cover primary-bg-dark" style={{ background: 'url(assets/img/bg2.png) no-repeat' }}
    >				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							
							<div className="jbs-head-bodys-top">
								<div className="jbs-roots-y1">
									<div className="jbs-roots-y1-first"><div className="jbs-roots-thumb"><figure><img src="assets/img/l-12.png" className="img-fluid" alt=""/></figure></div></div>
									<div className="jbs-roots-y1-last ps-3">
										<div className="jbs-urt mb-1"><span className="label text-light primary-2-bg">Full Time</span></div>
										<div className="jbs-title-iop"><h2 className="m-0 text-light">Sr. Front-end Designer</h2></div>
										<div className="jbs-locat-oiu text-sm-muted text-light d-flex align-items-center">
											<span><i className="fa-solid fa-location-dot opacity-75 me-1"></i>California, USA</span>
											<div className="jbs-kioyer-groups ms-3">
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star"></span>
												<span className="aal-reveis text-light opacity-75">4.6</span>
											</div>
										</div>
									</div>
								</div>
								<div className="jbs-roots-y2">
									<div className="jbs-roots-action-groups">
										<div className="jbs-roots-action-btns">
											<button type="button" className="btn btn-md btn-whites fw-medium text-primary" data-bs-toggle="modal" data-bs-target="#applyjob">Quick Apply</button>
											<button type="button" className="btn btn-md btn-dark ms-2 fw-medium">Save Job</button>
										</div>
										<div className="jbs-roots-action-info">
											<span className="text-light opacity-75 me-2">74 applicants</span>.<span className="text-sm fw-medium primary-2-cl ms-2">Posted 54 min ago</span>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>
			
			<section className="gray-simple">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12">
							
							<div className="jbs-blocs style_03 b-0 mb-md-4 mb-sm-4">
								<div className="jbs-blocs-body px-4 py-4">
									<div className="jbs-content mb-4">
										<h5>Job Description</h5>
										<p>Themezhub Web provides equal employment opportunities to all qualified individuals without regard to race, color, religion, sex, gender identity, sexual orientation, pregnancy, age, national origin, physical or mental disability, military or veteran status, genetic information, or any other protected classification. Equal employment opportunity includes, but is not limited to, hiring, training, promotion, demotion, transfer, leaves of absence, and termination. Thynk Web takes allegations of discrimination, harassment, and retaliation seriously, and will promptly investigate when such behavior is reported.</p>
										<p>Our company is seeking to hire a skilled Web Developer to help with the development of our current projects. Your duties will primarily revolve around building software by writing code, as well as modifying software to fix errors, adapt it to new hardware, improve its performance, or upgrade interfaces. You will also be involved in directing system testing and validation procedures, and also working with customers or departments on technical issues including software system design and maintenance.</p>
										<p className="m-0">We are looking for a Senior Web Developer to build and maintain functional web pages and applications. Senior Web Developer will be leading junior developers, refining website specifications, and resolving technical issues. He/She should have extensive experience building web pages from scratch and in-depth knowledge of at least one of the following programming languages: Javascript, Ruby, or PHP. He/She will ensure our web pages are up and running and cover both internal and customer needs.</p>
									</div>
									<div className="jbs-content-body mb-4">
										<h5>Job Requirements</h5>
										<div className="jbs-content mb-3">
											<h6>Requirements:</h6>
											<ul className="simple-list">
												<li>Candidate must have a Bachelors or Masters degree in Computer. (B.tech, Bsc or BCA/MCA)</li>
												<li>Candidate must have a good working knowledge of Javascript and Jquery.</li>
												<li>Good knowledge of HTML and CSS is required.</li>
												<li>Experience in Word press is an advantage</li>
												<li>Jamshedpur, Jharkhand: Reliably commute or planning to relocate before starting work (Required)</li>
											</ul>
										</div>
										
										<div className="jbs-content mb-4">
											<h6>Responsibilities:</h6>
											<ul className="simple-list">
												<li>Write clean, maintainable and efficient code.</li>
												<li>Design robust, scalable and secure features.</li>
												<li>Collaborate with team members to develop and ship web applications within tight timeframes.</li>
												<li>Work on bug fixing, identifying performance issues and improving application performance</li>
												<li>Write unit and functional testcases.</li>
												<li>Continuously discover, evaluate, and implement new technologies to maximise development efficiency. Handling complex technical iss</li>
											</ul>
										</div>
										
										<div className="jbs-content">
											<h6>Qualifications and Skills</h6>
											<ul className="colored-list">
												<li>Bachelor's degree</li>
												<li>BCA/MCA</li>
												<li>BSC IT/Msc IT</li>
												<li>Or any other equivalent degree</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="jbs-blox-footer">
									<div className="blox-first-footer">
										<div className="ftr-share-block">
											<ul>
												<li><strong>Share This Job:</strong></li>
												<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-facebook"></i></a></li>
												<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-linkedin"></i></a></li>
												<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-google-plus"></i></a></li>
												<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-twitter"></i></a></li>
											</ul>
										</div>
									</div>
									<div className="blox-last-footer">
										<div className="jbs-roots-action-btns">
											<button type="button" className="btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#applyjob">Quick Apply</button>
											<button type="button" className="btn btn-md btn-gray ms-2"><i className="fa-solid fa-heart me-2"></i>Save Job</button>
										</div>
									</div>
								</div>
							</div>
							
						</div>
						
						<div className="col-lg-4 col-md-12">
							<div className="detail-side-block bg-white mb-4">
								<div className="detail-side-head">
									<div className="side-flex-thumb">
										<img src="assets/img/l-12.png" className="img-fluid" alt=""/>
									</div>
									<div className="side-flex-caption">
										<div className="jbs-title-iop"><h4 className="m-0">Sr. Front-end Designer</h4></div>
										<div className="jbs-locat-oiu text-sm-muted">
											<span><i className="fa-solid fa-location-dot me-1"></i>California, USA</span>
										</div>
									</div>
								</div>
								<div className="detail-side-middle">
									<div className="form-group">
										<label>Name:</label>
										<input type="text" className="form-control" placeholder=""/>
									</div>
									<div className="form-group">
										<label>Email:</label>
										<input type="text" className="form-control" placeholder=""/>
									</div>
									<div className="form-group">
										<div className="upload-btn-wrapper full-width">
											<button className="btn full-width">Upload Resume</button>
											<input type="file" name="myfile"/>
										</div>
									</div>
									<div className="form-group">
										<div className="elsoci"><label>Are you authorised to work in India?</label></div>
										<div className="form-check form-check-inline">
											<input className="form-check-input" type="radio" name="workindia" id="wyes" value="option1"/>
											<label className="form-check-label" for="wyes">Yes</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input" type="radio" name="workindia" id="wno" value="option1"/>
											<label className="form-check-label" for="wno">No</label>
										</div>
									</div>
									<div className="form-group">
										<div className="elsoci"><label>Do you have master degree?</label></div>
										<div className="form-check form-check-inline">
											<input className="form-check-input" type="radio" name="degree" id="dyed" value="option1"/>
											<label className="form-check-label" for="dyed">Yes</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input" type="radio" name="degree" id="dno" value="option1"/>
											<label className="form-check-label" for="dno">No</label>
										</div>
									</div>
									<div className="form-group">
										<div className="form-check form-check-inline">
											<input className="form-check-input" type="checkbox" id="jobalert" value="option1"/>
											<label className="form-check-label" for="jobalert">Create Job Alert</label>
										</div>
									</div>
									<div className="form-group">
										<button type="button" className="btn btn-primary full-width font-sm">Apply Now</button>
									</div>
								</div>
							</div>
							
							<div className="side-jbs-info-blox bg-white mb-4">
								<div className="side-jbs-info-header">
									<div className="side-jbs-info-thumbs">
										<figure><img src="assets/img/l-12.png" className="img-fluid" alt=""/></figure>
									</div>
									<div className="side-jbs-info-captionyo ps-3">
										<div className="sld-info-title">
											<h5 className="rtls-title mb-1">Google Inc.</h5>
											<div className="jbs-locat-oiu text-sm-muted">
												<span className="me-1"><i className="fa-solid fa-location-dot me-1"></i>California, USA</span>.<span className="ms-1">Software & Consultancy</span>
											</div>
										</div>
									</div>
								</div>
								<div className="side-jbs-info-middle">
									<div className="side-full-info-groups">
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Company Founder:</span>
											<h6 className="sld-title">Mr. Daniel Mark</h6>
										</div>
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Industry:</span>
											<h6 className="sld-title">Technology</h6>
										</div>
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Founded:</span>
											<h6 className="sld-title">1997</h6>
										</div>
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Head Office:</span>
											<h6 className="sld-title">London, UK</h6>
										</div>
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Revenue</span>
											<h6 className="sld-title">$70B+</h6>
										</div>
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Company Size:</span>
											<h6 className="sld-title">20,000+ Emp.</h6>
										</div>
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Min Exp.</span>
											<h6 className="sld-title">02 Years</h6>
										</div>
										<div className="single-side-info">
											<span className="text-sm-muted sld-subtitle">Openings</span>
											<h6 className="sld-title">06 Openings</h6>
										</div>
									</div>
								</div>
							</div>
							
							<div className="side-rtl-jbs-block">
								<div className="side-rtl-jbs-head">
									<h5 className="side-jbs-titles">Related Jobs</h5>
								</div>
								<div className="side-rtl-jbs-body">
									<div className="side-rtl-jbs-groups">
										
										<div className="single-side-rtl-jbs">
											<div className="single-fliox">
												<div className="single-rtl-jbs-thumb">
													<a href="job-detail.html"><figure><img src="assets/img/l-1.png" className="img-fluid" alt=""/></figure></a>
												</div>
												<div className="single-rtl-jbs-caption">
													<div className="hjs-rtls-titles">
														<div className="jbs-types mb-1"><span className="label text-success bg-light-success">Full Time</span></div>
														<h5 className="rtls-title"><a href="https://shreethemes.net/jobstock-landing-2.2/jobstock/joob-detail.html">Sr. Front-end Designer</a></h5>
														<div className="jbs-locat-oiu text-sm-muted">
															<span><i className="fa-solid fa-location-dot me-1"></i>California, USA</span>
														</div>
													</div>
												</div>
											</div>
											<div className="single-rtl-jbs-hot">
												<div className="single-tag-rtls"><span className="label text-warning bg-light-warning"><i className="fa-brands fa-hotjar me-1"></i>New</span></div>
												<div className="single-tag-rtls"><span className="label text-success bg-light-success"><i className="fa-solid fa-star me-1"></i>Featured</span></div>
											</div>
										</div>
										
										<div className="single-side-rtl-jbs">
											<div className="single-fliox">
												<div className="single-rtl-jbs-thumb">
													<a href="job-detail.html"><figure><img src="assets/img/l-2.png" className="img-fluid" alt=""/></figure></a>
												</div>
												<div className="single-rtl-jbs-caption">
													<div className="hjs-rtls-titles">
														<div className="jbs-types mb-1"><span className="label text-success bg-light-success">Full Time</span></div>
														<h5 className="rtls-title"><a href="https://shreethemes.net/jobstock-landing-2.2/jobstock/joob-detail.html">Jr. PHP Developer</a></h5>
														<div className="jbs-locat-oiu text-sm-muted">
															<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>
														</div>
													</div>
												</div>
											</div>
											<div className="single-rtl-jbs-hot">
												<div className="single-tag-rtls"><span className="label text-success bg-light-success"><i className="fa-solid fa-star me-1"></i>Featured</span></div>
											</div>
										</div>
										
										<div className="single-side-rtl-jbs">
											<div className="single-fliox">
												<div className="single-rtl-jbs-thumb">
													<a href="job-detail.html"><figure><img src="assets/img/l-3.png" className="img-fluid" alt=""/></figure></a>
												</div>
												<div className="single-rtl-jbs-caption">
													<div className="hjs-rtls-titles">
														<div className="jbs-types mb-1"><span className="label text-danger bg-light-danger">Internship</span></div>
														<h5 className="rtls-title"><a href="https://shreethemes.net/jobstock-landing-2.2/jobstock/joob-detail.html">Project Manager For PHP</a></h5>
														<div className="jbs-locat-oiu text-sm-muted">
															<span><i className="fa-solid fa-location-dot me-1"></i>Liverpool, UK</span>
														</div>
													</div>
												</div>
											</div>
											<div className="single-rtl-jbs-hot">
												<div className="single-tag-rtls"><span className="label text-warning bg-light-warning"><i className="fa-brands fa-hotjar me-1"></i>New</span></div>
												<div className="single-tag-rtls"><span className="label text-success bg-light-success"><i className="fa-solid fa-star me-1"></i>Featured</span></div>
											</div>
										</div>
										
										<div className="single-side-rtl-jbs">
											<div className="single-fliox">
												<div className="single-rtl-jbs-thumb">
													<a href="job-detail.html"><figure><img src="assets/img/l-5.png" className="img-fluid" alt=""/></figure></a>
												</div>
												<div className="single-rtl-jbs-caption">
													<div className="hjs-rtls-titles">
														<div className="jbs-types mb-1"><span className="label text-warning bg-light-warning">Full Time</span></div>
														<h5 className="rtls-title"><a href="https://shreethemes.net/jobstock-landing-2.2/jobstock/joob-detail.html">Sr. Magento Developer 2.0</a></h5>
														<div className="jbs-locat-oiu text-sm-muted">
															<span><i className="fa-solid fa-location-dot me-1"></i>California, USA</span>
														</div>
													</div>
												</div>
											</div>
											<div className="single-rtl-jbs-hot">
												<div className="single-tag-rtls"><span className="label text-success bg-light-success"><i className="fa-solid fa-star me-1"></i>Featured</span></div>
											</div>
										</div>
										
										<div className="single-side-rtl-jbs">
											<div className="single-fliox">
												<div className="single-rtl-jbs-thumb">
													<a href="job-detail.html"><figure><img src="assets/img/l-6.png" className="img-fluid" alt=""/></figure></a>
												</div>
												<div className="single-rtl-jbs-caption">
													<div className="hjs-rtls-titles">
														<div className="jbs-types mb-1"><span className="label text-danger bg-light-danger">Internship</span></div>
														<h5 className="rtls-title"><a href="https://shreethemes.net/jobstock-landing-2.2/jobstock/joob-detail.html">Shopify Developer Fresher</a></h5>
														<div className="jbs-locat-oiu text-sm-muted">
															<span><i className="fa-solid fa-location-dot me-1"></i>New York, USA</span>
														</div>
													</div>
												</div>
											</div>
											<div className="single-rtl-jbs-hot">
												<div className="single-tag-rtls"><span className="label text-warning bg-light-warning"><i className="fa-brands fa-hotjar me-1"></i>New</span></div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
										
						</div>
					</div>
				</div>
			</section>
									
			
            <section className="bg-cover primary-bg-dark"  style={{ background: 'url(assets/img/footer-bg-dark.png) no-repeat' }} >
        				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-7 col-lg-10 col-md-12 col-sm-12">
							
							<div className="call-action-wrap">
								<div className="sec-heading center">
									<h2 className="lh-base mb-3 text-light">Find The Perfect Job<br/>on Job Stock That is Superb For You</h2>
									<p className="fs-6 text-light">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias</p>
								</div>
								<div className="call-action-buttons mt-3">
									<a href="JavaScript:Void(0);" className="btn btn-lg btn-dark fw-medium px-xl-5 px-lg-4 me-2">Upload resume</a>
									<a href="JavaScript:Void(0);" className="btn btn-lg btn-whites fw-medium px-xl-5 px-lg-4 text-primary">Join Our Team</a>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>
                
    <Footer/>

</div>
  );
}

export default Job_Apply;

       
