import React from 'react';
import { Link } from 'react-router-dom';
function Call_to_action_1() {
  return (
    <>
    <section 
  className="bg-cover primary-bg-dark" 
  style={{ 
    backgroundColor: '#1ca774' 
  }}>        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-12 col-sm-12">
                    
                    <div className="call-action-wrap">
                        <div className="sec-heading center">
                            <h2 className="mb-3 lh-base text-light">Find The Perfect Job<br/>on Job Stock That is Superb For You</h2>
                            <p className="text-light fs-6">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias</p>
                        </div>
                        <div className="call-action-buttons mt-3">
                        <Link><button className='btn btn-dark m-1'>Upload resume</button></Link>
                        <Link><button className='btn btn-whites m-1'>Join Our Team</button></Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default Call_to_action_1;

       
