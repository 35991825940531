import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import EM_nav_dashboard from './Dashboard_nav';
function EM_Applicants() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
    <div className="dashboard-wrap bg-light">
				<a className="mobNavigation" data-bs-toggle="collapse" href="employer-dashboard.html#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
					<i className="fas fa-bars mr-2"></i>Dashboard Navigation
				</a>
				 <EM_nav_dashboard/>
				
                 <div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Manage Applicants</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="employer-applicants-jobs.html#">Employer</a></li>
										<li className="breadcrumb-item text-muted"><a href="employer-applicants-jobs.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="employer-applicants-jobs.html#" className="text-primary">All Applicants</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
						
						
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<div className="_mp-inner-content elior">
											<div className="_mp-inner-first">
												<div className="search-inline">
													<input type="text" className="form-control" placeholder="Job title, Keywords etc."/>
													<button type="button" className="btn btn-primary">Search</button>
												</div>
											</div>
											<div className="_mp_inner-last">
												<div className="item-shorting-box-right">
													<div className="shorting-by me-2 small">
														<select>
															<option value="0">Short by (Default)</option>
															<option value="1">Short by (Featured)</option>
															<option value="2">Short by (Urgent)</option>
															<option value="3">Short by (Post Date)</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
									
										<div className="row mb-3">
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="duster-flex-row  align-items-center d-flex justify-content-between">
													<div className="duster-flex-first">
														<h6 className="mb-0">Sr. Magento Developer</h6>
													</div>
													<div className="duster-flex-end">
														<ul className="nav nav-pills nav-fill gap-2 p-1 small gray-simple rounded" id="pillNav2" role="tablist">
															<li className="nav-item" role="presentation">
																<button className="nav-link active rounded" id="alls" data-bs-toggle="tab" type="button" role="tab" aria-selected="true">All: 194</button>
															</li>
															<li className="nav-item" role="presentation">
																<button className="nav-link rounded" id="approveds" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">Approved: 66</button>
															</li>
															<li className="nav-item" role="presentation">
																<button className="nav-link rounded" id="rejecteds" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">Rejected: 128</button>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										
										<div className="row justify-content-start gx-3 gy-4">
									
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-1.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Kr. Dhananjay Preet</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Sr. Web Designer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>London</span><span>Applied: 10 March 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-6.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Clarence B. Mantooth</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Sr. Content Writer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span><span>Applied: 10 Jan 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-7.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Geraldine E. Scott</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Shopify Developer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Denver, USA</span><span>Applied: 17 March 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-8.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Donald J. Merrick</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Laravel Developer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California, USA</span><span>Applied: 20 March 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-9.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Rudolph J. Chalmers</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Team Manager</span>.<span><i className="fa-solid fa-location-dot me-1"></i>New York, USA</span><span>Applied: 26 March 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-10.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Kr. Dhananjay Preet</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Front-End Designer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California, USA</span><span>Applied: 30 March 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-11.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Jennifer R. Jordan</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>UI/UX Designer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span><span>Applied: 14 Apr 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-12.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Jennifer R. Joyce</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Content Writer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Liverpool, UK</span><span>Applied: 15 Dec 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-13.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Elizabeth A. Finn</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>WordPress Developer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>New York, USA</span><span>Applied: 10 Nov 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-14.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Jewel E. Gardner</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>PHP Developer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>London, UK</span><span>Applied: 25 Dec 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12 col-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head m-0">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-usrs-thumb jbs-verified"><a href="candidate-detail.html"><figure><img src="assets/img/team-15.jpg" className="img-fluid circle" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-title-wrap"><h4><a href="candidate-detail.html" className="jbs-job-title">Rudolph S. Martin</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Sr. Web Designer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span><span>Applied: 10 Jan 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="rounded btn-md text-success bg-light-success px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Approve Candidate"><i className="fa-solid fa-check-double"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-info bg-light-info px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Reload"><i className="fa-solid fa-arrow-rotate-right"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-warning bg-light-warning px-3 me-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Download Resume"><i className="fa-solid fa-download"></i></a>
															<a href="JavaScript:Void(0);" className="rounded btn-md text-danger bg-light-danger px-3" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Remove Candidate"><i className="fa-solid fa-trash-can"></i></a>
														</div>
													</div>
												</div>
											</div>
											
										</div>
										
									</div>
								</div>
							</div>	
						</div>
	
					</div>
					
					
		
				</div>	
				
			</div>

            

</div>
  );
}

export default EM_Applicants;

       
