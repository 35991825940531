import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Dashboard_Nav from './User_dashboard_nav';
function Resume() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <div className="dashboard-wrap bg-light">
				<Dashboard_Nav/>
				
				<div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">My Resume</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="candidate-resume.html#">Candidate</a></li>
										<li className="breadcrumb-item text-muted"><a href="candidate-resume.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="candidate-resume.html#" className="text-primary">My Resume</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
						
						<div className="card">
							<div className="card-header">
								<h4>My Resume</h4>
							</div>
							<div className="card-body">
								<div className="row gx-4 gy-4 mb-3">
									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="cd-resume-wraps">
											<div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div>
											<div className="cd-resume-caption">
												<div className="cd-resume-content">
													<p>new-resume-123</p>
													<h5>PDF</h5>
												</div>
												<div className="cd-resume-icon"><i className="fa-solid fa-file-pdf"></i></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="cd-resume-wraps">
											<div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div>
											<div className="cd-resume-caption">
												<div className="cd-resume-content">
													<p>new-resume-updated</p>
													<h5>DOCS</h5>
												</div>
												<div className="cd-resume-icon"><i className="fa-regular fa-file-word"></i></div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-6">
										<div className="cd-resume-wraps">
											<div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div>
											<div className="cd-resume-caption">
												<div className="cd-resume-content">
													<p>new-resume-new</p>
													<h5>PDF</h5>
												</div>
												<div className="cd-resume-icon"><i className="fa-solid fa-file-pdf"></i></div>
											</div>
										</div>
									</div>
								</div>
								<div className="row gx-4 gy-4">
									<div className="col-xl-12 col-lg-12 col-md-12">
										<div className="upload-btn-wrapper">
											<button className="btn">Upload a file</button>
											<input type="file" name="myfile"/>
										</div>
									</div>
								</div>
							</div>
						</div>	
						
						<div className="card">
							<div className="card-header">
								<h4>Education</h4>
							</div>
							<div className="card-body">
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#secondarySchool" role="button" aria-expanded="false" aria-controls="secondarySchool">Secondary School</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="secondarySchool">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Education Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="High School"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Academy Name</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Awadh Primary School"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Passing year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="2008"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#intermediate" role="button" aria-expanded="false" aria-controls="intermediate">Intermediate</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="intermediate">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Education Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Intermidiate"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Academy Name</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Awadh Primary School"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Passing year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="2010"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#bachelorDegree" role="button" aria-expanded="false" aria-controls="bachelorDegree">Bachelor Degree</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="bachelorDegree">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Education Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Bachelor Appliation Of Computer"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Academy Name</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Swami Vivekanand University"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Passing year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="2013"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#masterDegree" role="button" aria-expanded="false" aria-controls="masterDegree">Master Degree</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="masterDegree">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Education Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Master Application Of Computer"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Academy Name</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Awadh University"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Passing year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="2016"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<a href="JavaScript:Void(0);" data-bs-toggle="modal" data-bs-target="#education" className="add-light-btn">Add More Education</a>
								</div>
								
							</div>
						</div>							
						<div className="card">
							<div className="card-header">
								<h4>Experience</h4>
							</div>
							<div className="card-body">
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#exp1" role="button" aria-expanded="false" aria-controls="exp1">Front-End Developer</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="exp1">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Job Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Front-End Developer"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Joinin Date</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="10-06-2008"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">End Date</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="15-04-2010"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Company Name</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Themezhub Technology"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
                                <div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#exp2" role="button" aria-expanded="false" aria-controls="exp2">WordPress Developer</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="exp2">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Job Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="WordPress Developer"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Joinin Date</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2011"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">End Date</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="15-05-2015"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Company Name</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Google Inc"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#exp3" role="button" aria-expanded="false" aria-controls="exp3">Magento Developer</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="exp3">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Job Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Sr. magento Developer"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Joinin Date</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2016"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">End Date</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="15-05-2022"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Company Name</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="Google Inc"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<a href="JavaScript:Void(0);" data-bs-toggle="modal" data-bs-target="#experience" className="add-light-btn">Add More Experience</a>
								</div>
							</div>
						</div>							
						<div className="card">
							<div className="card-header">
								<h4>Award</h4>
							</div>
							<div className="card-body">
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#BCPIL" role="button" aria-expanded="false" aria-controls="BCPIL">BCPIL Award</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="BCPIL">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="BCPIL Award"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2012"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#RIZZA" role="button" aria-expanded="false" aria-controls="RIZZA">RIZZA Award</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="RIZZA">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="RIZZA Award"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2016"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="candidate-resume.html#FIFFA" role="button" aria-expanded="false" aria-controls="FIFFA">FIFFA Award</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="FIFFA">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="FIFFA ICL"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2022"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<a href="JavaScript:Void(0);" data-bs-toggle="modal" data-bs-target="#award" className="add-light-btn">Add More Award</a>
								</div>
							</div>
						</div>							
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<button type="submit" className="btn ft--medium btn-primary">Save Resume</button>
							</div>	
						</div>
	
					</div>
					
					
				</div>	
                
                		
				
			</div>
                

</div>
  );
}

export default Resume;

       
