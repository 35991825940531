import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Dashboard_Nav from './User_dashboard_nav';
function Change_password() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <div className="dashboard-wrap bg-light">
				<Dashboard_Nav/>
				
				<div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Change Password</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="candidate-change-password.html#">Candidate</a></li>
										<li className="breadcrumb-item text-muted"><a href="candidate-change-password.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="candidate-change-password.html#" className="text-primary">Change Password</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
						
						<div className="card">
							<div className="card-header">
								<h4>Change Your Password</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row mb-3">
										<label className="col-xl-2 col-md-12 col-form-label">Old Password</label>
										<div className="col-xl-7 col-md-12">
											<input type="text" className="form-control" placeholder="*******"/>
										</div>
									</div> 
									<div className="row mb-3">
										<label className="col-xl-2 col-md-12 col-form-label">New Password</label>
										<div className="col-xl-7 col-md-12">
											<input type="text" className="form-control" placeholder="*******"/>
										</div>
									</div>
									<div className="row mb-3">
										<label className="col-xl-2 col-md-12 col-form-label">Confirm Password</label>
										<div className="col-xl-7 col-md-12">
											<input type="text" className="form-control" placeholder="*******"/>
										</div>
									</div>
									<div className="row mb-3">
										<div className="col-xl-12 col-md-12">
											<button type="button" className="btn btn-primary">Change password</button>
										</div>
									</div>
								</form>
							</div>
						</div>
	
					</div>
					
		
				</div>				
                
                		
				
			</div>
                

</div>
  );
}

export default Change_password;

       
