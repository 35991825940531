import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import EM_nav_dashboard from './Dashboard_nav';
function EM_Profile() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
    <div className="dashboard-wrap bg-light">
				<a className="mobNavigation" data-bs-toggle="collapse" href="employer-dashboard.html#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
					<i className="fas fa-bars mr-2"></i>Dashboard Navigation
				</a>
				 <EM_nav_dashboard/>
				
                 <div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Update Profile</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="employer-profile.html#">Employer</a></li>
										<li className="breadcrumb-item text-muted"><a href="employer-profile.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="employer-profile.html#" className="text-primary">My Profile</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
					
						<div className="dashboard-profle-wrapper mb-4">
							<div className="dash-prf-start">
								<div className="dash-prf-start-upper mb-2">
									<div className="dash-prf-start-thumb jbs-verified">
										<figure className="mb-0"><img src="assets/img/l-5.png" className="img-fluid rounded" alt=""/></figure>
									</div>
								</div>
								<div className="dash-prf-start-bottom">
									<div className="upload-btn-wrapper small">
										<button className="btn">Change Profile</button>
										<input type="file" name="myfile"/>
									</div>
								</div>
							</div>
							<div className="dash-prf-end">
								<div className="dash-prfs-caption emplyer">
									<div className="dash-prfs-flexfirst">
										<div className="dash-prfs-title">
											<h4>Adobe Photoshop</h4>	
										</div>
										<div className="dash-prfs-subtitle">
											<div className="jbs-job-mrch-lists">
												<div className="single-mrch-lists">
													<span>Software & Application</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>
												</div>
											</div>
										</div>
										<div className="jbs-kioyer mt-1">
											<div className="jbs-kioyer-groups justify-content-start">
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star active"></span>
												<span className="fa-solid fa-star"></span>
												<span className="aal-reveis">4.8</span>
											</div>
										</div>
									</div>
									<div className="dash-prfs-flexends">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
											<label className="form-check-label" for="flexSwitchCheckChecked">Show Profile</label>
										</div>	
									</div>
								</div>
								<div className="dash-prf-caption-end">
									<div className="dash-prf-infos">
										<div className="single-dash-prf-infos">
											<div className="single-dash-prf-infos-icons"><i className="fa-solid fa-envelope-open-text"></i></div>
											<div className="single-dash-prf-infos-caption">
												<p className="text-sm-muted mb-0">Email</p>
												<h5>Themezhub@gmail.com</h5>
											</div>
										</div>
										<div className="single-dash-prf-infos">
											<div className="single-dash-prf-infos-icons"><i className="fa-solid fa-phone-volume"></i></div>
											<div className="single-dash-prf-infos-caption">
												<p className="text-sm-muted mb-0">Call</p>
												<h5>+91 256 356 8547</h5>
											</div>
										</div>
										<div className="single-dash-prf-infos">
											<div className="single-dash-prf-infos-icons"><i className="fa-solid fa-briefcase"></i></div>
											<div className="single-dash-prf-infos-caption">
												<p className="text-sm-muted mb-0">Emp.</p>
												<h5>2000 - 5000</h5>
											</div>
										</div>
									</div>
									<div className="dash-prf-completion">
										<p className="text-sm-muted">Profile Completed</p>
										<div className="progress" role="progressbar" aria-label="Example Success with label" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div className="progress-bar bg-success" style={{ width: '75%' }}>
      75%
    </div>										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header">
								<h4>Basic Detail</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
									
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Employer Name</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Email ID</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Phone No.</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Website</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Founded Year</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Company Category</label>
												<div className="select-ops">
													<select>
														<option value="1">Web & Application</option>
														<option value="2">Banking Services</option>
														<option value="3">UI/UX Design</option>
														<option value="4">IOS/App Application</option>
														<option value="5">Education</option>
													</select>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Company Size</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Video</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="form-group">
												<label>About Company</label>
												<textarea className="form-control ht-80"></textarea>
											</div>
										</div>
										
									</div> 
								</form>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header">
								<h4>Award</h4>
							</div>
							<div className="card-body">
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="employer-profile.html#BCPIL" role="button" aria-expanded="false" aria-controls="BCPIL">BCPIL Award</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="BCPIL">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="BCPIL Award"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2012"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="employer-profile.html#RIZZA" role="button" aria-expanded="false" aria-controls="RIZZA">RIZZA Award</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="RIZZA">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="RIZZA Award"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2016"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<div className="single-edc-box">
										<div className="single-edc-remove-box"><div className="cd-resume-cancel"><a href="JavaScript:Void(0);" className="cancel-link"><i className="fa-solid fa-xmark"></i></a></div></div>
										<div className="single-edc-title-box">
											<a className="btn btn-collapse" data-bs-toggle="collapse" href="employer-profile.html#FIFFA" role="button" aria-expanded="false" aria-controls="FIFFA">FIFFA Award</a>
										</div>
									</div>
									<div className="single-edc-caption">
										<div className="collapse" id="FIFFA">
											<div className="card card-body">
												<form>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Title</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="FIFFA ICL"/>
														</div>
													</div> 
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Award Year</label>
														<div className="col-md-10">
															<input type="text" className="form-control" value="01-12-2022"/>
														</div>
													</div>
													<div className="row mb-3">
														<label className="col-md-2 col-form-label">Description</label>
														<div className="col-md-10">
															<textarea className="form-control ht-80">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
								<div className="single-edc-wrap">
									<a href="JavaScript:Void(0);" data-bs-toggle="modal" data-bs-target="#award" className="add-light-btn">Add More Award</a>
								</div>
							</div>
						</div>	
						
						<div className="card">
							<div className="card-header">
								<h4>Contact Detail</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
									
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Your Email</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Phone no.</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Temporary Address</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Address</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Address 2</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Country</label>
												<div className="select-ops">
													<select>
														<option value="1">India</option>
														<option value="2">United State</option>
														<option value="3">United kingdom</option>
														<option value="4">Austrailia</option>
														<option value="5">Russia</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>State/City</label>
												<div className="select-ops">
													<select>
														<option value="1">California</option>
														<option value="2">Denver</option>
														<option value="3">New York</option>
														<option value="4">Canada</option>
														<option value="5">Poland</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Zip Code</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Latitude</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>longitude</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
									</div> 
								</form>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header">
								<h4>Social Login</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
									
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Facebook</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Twitter</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Instagram</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Linked In</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Twitter</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Google Plus</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
									</div> 
								</form>
							</div>
						</div>
						
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<button type="submit" className="btn ft--medium btn-primary">Save Profile</button>
							</div>	
						</div>
	
					</div>
					
					
		
				</div>				
				
			</div>

            <div className="modal fade" id="award" tabindex="-1" role="dialog" aria-labelledby="messagemodal" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered award-pop-form" role="document">
					<div className="modal-content" id="awardmodal">
						<span className="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i className="fas fa-close"></i></span>
						<div className="modal-body">
							<div className="text-center">
								<h4 className="mb-3">Add your Award</h4>
							</div>
							<div className="added-form">
								<form>
									<div className="row mb-3">
										<label className="col-md-12 col-form-label">Award Title</label>
										<div className="col-md-12">
											<input type="text" className="form-control"/>
										</div>
									</div> 
									<div className="row mb-3">
										<label className="col-md-12 col-form-label">Award Year</label>
										<div className="col-md-12">
											<input type="date" className="form-control"/>
										</div>
									</div>
									<div className="row mb-3">
										<label className="col-md-12 col-form-label">Description</label>
										<div className="col-md-12">
											<textarea className="form-control ht-80"></textarea>
										</div>
									</div>
									<div className="row mb-3">
										<div className="col-md-12">
											<button type="submit" className="btn full-width btn-primary">Save Award</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>  		

</div>
  );
}

export default EM_Profile;

       
