import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Dashboard_Nav from './User_dashboard_nav';
function Dashboard() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <div className="dashboard-wrap bg-light">
				<Dashboard_Nav/>
				
				<div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-5">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Candidate Dashboard</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="candidate-dashboard.html#">Candidate</a></li>
										<li className="breadcrumb-item text-muted"><a href="candidate-dashboard.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="candidate-dashboard.html#" className="text-primary">Candidate Statistics</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
						
						<div className="row align-items-center gx-4 gy-4 mb-4">
							<div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
								<div className="dash-wrap-bloud">
									<div className="dash-wrap-bloud-icon">
										<div className="bloud-icon text-success bg-light-success">
											<i className="fa-solid fa-business-time"></i>	
										</div>
									</div>
									<div className="dash-wrap-bloud-caption">
										<div className="dash-wrap-bloud-content">
											<h5 className="ctr">523</h5>
											<p>Applied jobs</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
								<div className="dash-wrap-bloud">
									<div className="dash-wrap-bloud-icon">
										<div className="bloud-icon text-warning bg-light-warning">
											<i className="fa-solid fa-bookmark"></i>	
										</div>
									</div>
									<div className="dash-wrap-bloud-caption">
										<div className="dash-wrap-bloud-content">
											<h5 className="ctr">523</h5>
											<p>Saved Jobs</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
								<div className="dash-wrap-bloud">
									<div className="dash-wrap-bloud-icon">
										<div className="bloud-icon text-danger bg-light-danger">
											<i className="fa-solid fa-eye"></i>
										</div>
									</div>
									<div className="dash-wrap-bloud-caption">
										<div className="dash-wrap-bloud-content">
											<h5 className="ctr">523</h5>
											<p>Viewed Jobs</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
								<div className="dash-wrap-bloud">
									<div className="dash-wrap-bloud-icon">
										<div className="bloud-icon text-info bg-light-info">
											<i className="fa-sharp fa-solid fa-comments"></i>
										</div>
									</div>
									<div className="dash-wrap-bloud-caption">
										<div className="dash-wrap-bloud-content">
											<h5 className="ctr">523</h5>
											<p>Total Review</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="row gx-4 gy-4 mb-4">
							<div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
								<div className="card d-none d-lg-block">
									<div className="card-header">
										<h4 className="mb-0">Extra Area Chart</h4>
									</div>
									<div className="card-body">
										<ul className="list-inline text-center m-t-40">
											<li>
												<h5><i className="fa fa-circle me-1 text-warning"></i>Applied jobs</h5>
											</li>
											<li>
												<h5><i className="fa fa-circle me-1 text-danger"></i>Viewed Jobs</h5>
											</li>
											<li>
												<h5><i className="fa fa-circle me-1 text-success"></i>Saved jobs</h5>
											</li>
										</ul>
									</div>
								</div>
							</div>
							
							<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<h4>Notifications</h4>
									</div>
									<div className="ground-list ground-list-hove">
										<div className="ground ground-single-list">
											<a href="JavaScript:Void(0);">
												<div className="btn-circle-40 text-warning bg-light-warning"><i className="fas fa-home"></i></div>
											</a>

											<div className="ground-content">
												<h6><a href="JavaScript:Void(0);"><strong>Kr. Shaury Preet</strong> Replied your message</a></h6>
												<span className="small">Just Now</span>
											</div>
										</div>
										
										<div className="ground ground-single-list">
											<a href="JavaScript:Void(0);">
												<div className="btn-circle-40 text-danger bg-light-danger"><i className="fa-solid fa-comments"></i></div>
											</a>

											<div className="ground-content">
												<h6><a href="JavaScript:Void(0);">Mortin Denver accepted your resume on <strong>Job Stock</strong></a></h6>
												<span className="small">20 min ago</span>
											</div>
										</div>
										
										<div className="ground ground-single-list">
											<a href="JavaScript:Void(0);">
												<div className="btn-circle-40 text-info bg-light-info"><i className="fa-solid fa-heart"></i></div>
											</a>

											<div className="ground-content">
												<h6><a href="JavaScript:Void(0);">Your job #456256 expired yesterday <strong>View More</strong></a></h6>
												<span className="small">1 day ago</span>
											</div>
										</div>
										
										<div className="ground ground-single-list">
											<a href="JavaScript:Void(0);">
												<div className="btn-circle-40 text-danger bg-light-danger"><i className="fa-solid fa-thumbs-up"></i></div>
											</a>

											<div className="ground-content">
												<h6><a href="JavaScript:Void(0);"><strong>Daniel Kurwa</strong> has been approved your resume!.</a></h6>
												<span className="small">10 days ago</span>
											</div>
										</div>
										
										<div className="ground ground-single-list">
											<a href="JavaScript:Void(0);">
												<div className="btn-circle-40 text-success bg-light-success"><i className="fa-solid fa-comment-dots"></i></div>
											</a>

											<div className="ground-content">
												<h6><a href="JavaScript:Void(0);">Khushi Verma left a review on <strong>Your Message</strong></a></h6>
												<span className="small">Just Now</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<h4 className="mb-0">Shortlisted Jobs</h4>
									</div>
									<div className="card-body px-4 py-4">
										<div className="row justify-content-start gx-3 gy-4">
									
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-1.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Tripadvisor</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California</span>.<span>07 Apr 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$85K - 95K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-2.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Pinterest</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Allahabad</span>.<span>2 Apr 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$90K - 100K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-3.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Shopify</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>15 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$90K - 115K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-4.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Jr. Laravel Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Dreezoo</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Liverpool, UK</span>.<span>20 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$85K - 110K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-5.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Enternship</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Java & Python Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Photoshop</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California</span>.<span>20 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$90K - 120K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-6.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. Code Ignetor Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Firefox</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>18 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$80K - 90K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-7.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Part Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. Magento Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Airbnb</span>.<span><i className="fa-solid fa-location-dot me-1"></i>London, UK</span>.<span>15 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$75K - 85K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-8.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">New Shopify Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Snapchat</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Denver, USA</span>.<span>15 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$70K - 95K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-9.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Front-end Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Dribbble</span>.<span><i className="fa-solid fa-location-dot me-1"></i>New York, USA</span>.<span>7 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$60K - 70K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-10.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Technical Content Writer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Skype</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>10 March 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$80K - 100K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-outline-secondary px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					
		
				</div>		
                
                		
				
			</div>
                

</div>
  );
}

export default Dashboard;

       
