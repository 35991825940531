import React from 'react';
import { Link } from 'react-router-dom';
import shape1 from '../img/icon/shape1.svg'
import shape2 from '../img/icon/shape2.svg'
import bg1 from '../img/icon/bg1.png'
function Footer() {
  return (
       <>
         <footer className="footer skin-light-footer">
    
    <div className="footer-top">
        <div className="container">
            <div className="row align-items-center justify-content-between">
            
                <div className="col-xl-5 col-lg-5 col-md-5">
                    <div className="call-action-form rounded m-0">
                        <form className="ms-0">
                            <div className="newsltr-form gray-style">
                                <input type="text" className="form-control" placeholder="Enter Your email"/>
                                <button type="button" className="btn btn-subscribe">Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>
                
                <div className="col-xl-7 col-lg-7 col-md-7">
                    <div className="job-info-count-group lg-ctr">
                        <div className="single-jb-info-count">
                            <div className="jbs-y7"><h5 className="ctr">12</h5><span className="primary-text-dark">K</span></div>
                            <div className="jbs-y5"><p>Job Posted</p></div>
                        </div>
                        <div className="single-jb-info-count">
                            <div className="jbs-y7"><h5 className="ctr">10</h5><span className="primary-text-dark">M</span></div>
                            <div className="jbs-y5"><p>Happy Customers</p></div>
                        </div>
                        <div className="single-jb-info-count">
                            <div className="jbs-y7"><h5 className="ctr">76</h5><span className="primary-text-dark">K</span></div>
                            <div className="jbs-y5"><p>Freelancers</p></div>
                        </div>
                        <div className="single-jb-info-count">
                            <div className="jbs-y7"><h5 className="ctr">200</h5><span className="primary-text-dark">+</span></div>
                            <div className="jbs-y5"><p>Companies</p></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    <div>
        <div className="container">
            <div className="row">
                
                <div className="col-lg-3 col-md-4">
                    <div className="footer-widget">
                        <img src="assets/img/logo.png" className="img-footer" alt=""/>
                        <div className="footer-add">
                            <p>Collins Street West, Victoria Near Bank Road<br/>Australia QHR12456.</p>
                        </div>
                        <div className="foot-socials">
                            <ul>
                                <li><a href="JavaScript:Void(0);"><i className="fa-brands fa-facebook"></i></a></li>
                                <li><a href="JavaScript:Void(0);"><i className="fa-brands fa-linkedin"></i></a></li>
                                <li><a href="JavaScript:Void(0);"><i className="fa-brands fa-google-plus"></i></a></li>
                                <li><a href="JavaScript:Void(0);"><i className="fa-brands fa-twitter"></i></a></li>
                                <li><a href="JavaScript:Void(0);"><i className="fa-brands fa-dribbble"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>		
                <div className="col-lg-2 col-md-4">
                    <div className="footer-widget">
                        <h4 className="widget-title text-primary">For Clients</h4>
                        <ul className="footer-menu">
                            <li><a href="JavaScript:Void(0);">Talent Marketplace</a></li>
                            <li><a href="JavaScript:Void(0);">Payroll Services</a></li>
                            <li><a href="JavaScript:Void(0);">Direct Contracts</a></li>
                            <li><a href="JavaScript:Void(0);">Hire Worldwide</a></li>
                            <li><a href="JavaScript:Void(0);">Hire in the USA</a></li>
                            <li><a href="JavaScript:Void(0);">How to Hire</a></li>
                        </ul>
                    </div>
                </div>
                        
                <div className="col-lg-2 col-md-4">
                    <div className="footer-widget">
                        <h4 className="widget-title text-primary">Our Resources</h4>
                        <ul className="footer-menu">
                            <li><a href="JavaScript:Void(0);">Free Business tools</a></li>
                            <li><a href="JavaScript:Void(0);">Affiliate Program</a></li>
                            <li><a href="JavaScript:Void(0);">Success Stories</a></li>
                            <li><a href="JavaScript:Void(0);">Upwork Reviews</a></li>
                            <li><a href="JavaScript:Void(0);">Resources</a></li>
                            <li><a href="JavaScript:Void(0);">Help & Support</a></li>
                        </ul>
                    </div>
                </div>
                
                <div className="col-lg-2 col-md-6">
                    <div className="footer-widget">
                        <h4 className="widget-title text-primary">The Company</h4>
                        <ul className="footer-menu">
                            <li><a href="JavaScript:Void(0);">About Us</a></li>
                            <li><a href="JavaScript:Void(0);">Leadership</a></li>
                            <li><a href="JavaScript:Void(0);">Contact Us</a></li>
                            <li><a href="JavaScript:Void(0);">Investor Relations</a></li>
                            <li><a href="JavaScript:Void(0);">Trust, Safety & Security</a></li>
                        </ul>
                    </div>
                </div>
                
                <div className="col-lg-3 col-md-6">
                    <div className="footer-widget">
                        <h4 className="widget-title text-primary">Download Apps</h4>	
                        <div className="app-wrap">
                            <p><a href="JavaScript:Void(0);"><img src="assets/img/light-play.png" className="img-fluid" alt=""/></a></p>
                            <p><a href="JavaScript:Void(0);"><img src="assets/img/light-ios.png" className="img-fluid" alt=""/></a></p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    <div className="footer-bottom">
        <div className="container">
            <div className="row align-items-center justify-content-center">
                
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <p className="mb-0 text-center">© 2015 - 2023 Job Stock® Themezhub.</p>
                </div>
                
            </div>
        </div>
    </div>
</footer>

<div className="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered login-pop-form" role="document">
        <div className="modal-content" id="loginmodal">
            <span className="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i className="fas fa-close"></i></span>
            <div className="modal-header">
                <div className="mdl-thumb"><img src="assets/img/ico.png" className="img-fluid" width="70" alt=""/></div>
                <div className="mdl-title"><h4 className="modal-header-title">Hello, Again</h4></div>
            </div>
            <div className="modal-body">
                <div className="modal-login-form">
                    <form>
                    
                        <div className="form-floating mb-4">
                            <input type="email" className="form-control" placeholder="name@example.com"/>
                            <label>User Name</label>
                        </div>
                        
                        <div className="form-floating mb-4">
                            <input type="password" className="form-control" placeholder="Password"/>
                            <label>Password</label>
                        </div>
                        
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Log In</button>
                        </div>
                        
                        <div className="modal-flex-item mb-3">
                            <div className="modal-flex-first">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="savepassword" value="option1"/>
                                    <label className="form-check-label" for="savepassword">Save Password</label>
                                </div>
                            </div>
                            <div className="modal-flex-last">
                                <a href="JavaScript:Void(0);">Forget Password?</a>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="social-login">
                    <ul>
                        <li><a href="JavaScript:Void(0);" className="btn connect-fb"><i className="fa-brands fa-facebook"></i>Facebook</a></li>
                        <li><a href="JavaScript:Void(0);" className="btn connect-google"><i className="fa-brands fa-google"></i>Google+</a></li>
                    </ul>
                </div>
            </div>
            <div className="modal-footer">
                <p>Don't have an account yet?<a href="signup.html" className="text-primary font--bold ms-1">Sign Up</a></p>
            </div>
        </div>
    </div>
</div>

<div className="modal fade" id="filter" tabindex="-1" role="dialog" aria-labelledby="filtermodal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered filter-popup" role="document">
        <div className="modal-content" id="filtermodal">
            <span className="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i className="fas fa-close"></i></span>
            <div className="modal-header">
                <h4 className="modal-header-sub-title">Start Your Filter</h4>
            </div>
            <div className="modal-body p-0">
                <div className="filter-content">
                    <div className="full-tabs-group">
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Job Match Score</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <div className="d-flex flex-wrap">
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="msix"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="msix">6.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="msixfive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="msixfive">6.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="mseven"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="mseven">7.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="msevenfive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="msevenfive">7.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="meight"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="meight">8.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="meightfive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="meightfive">8.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="mnine"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="mnine">9.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="mninefive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="mninefive">9.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="mten"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="mten">10</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Job Value Score</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <div className="d-flex flex-wrap">
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="vsix"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="vsix">6.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="vsixfive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="vsixfive">6.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="vseven"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="vseven">7.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="vsevenfive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="vsevenfive">7.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="veight"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="veight">8.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="veightfive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="veightfive">8.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="vnine"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="vnine">9.0</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="vninefive"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="vninefive">9.5</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="vten"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="vten">10</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Place Of Work</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <div className="d-flex flex-wrap">
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="anywhere" checked/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="anywhere">Anywhere</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="onsite"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="onsite">On Site</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="remote"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="remote">Fully Remote</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Type Of Contract</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <div className="d-flex flex-wrap">
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="employee1"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="employee1">Employee</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="frelancers1"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="frelancers1">Freelancer</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="contractor1"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="contractor1">Contractor</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="internship1"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="internship1">Internship</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Type Of Employment</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <div className="d-flex flex-wrap">
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="fulltime"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="fulltime">Full Time</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="parttime"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="parttime">Part Time</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="freelance2" checked/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="freelance2">Freelance</label>
                                    </div>
                                    <div className="sing-btn-groups">
                                        <input type="checkbox" className="btn-check" id="internship2"/>
                                        <label className="btn btn-md btn-outline-primary font--bold rounded-5" for="internship2">Internship</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Radius In Miles</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <div className="rg-slider">
                                     <input type="text" className="js-range-slider" name="my_range" value=""/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Explore Top Categories</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <ul className="row p-0 m-0">
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-1" className="form-check-input" name="s-1" type="checkbox"/>
                                            <label for="s-1" className="form-check-label">IT Computers</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-2" className="form-check-input" name="s-2" type="checkbox"/>
                                            <label for="s-2" className="form-check-label">Web Design</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-3" className="form-check-input" name="s-3" type="checkbox"/>
                                            <label for="s-3" className="form-check-label">Web development</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-4" className="form-check-input" name="s-4" type="checkbox"/>
                                            <label for="s-4" className="form-check-label">SEO Services</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-5" className="form-check-input" name="s-5" type="checkbox"/>
                                            <label for="s-5" className="form-check-label">Financial Service</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-6" className="form-check-input" name="s-6" type="checkbox"/>
                                            <label for="s-6" className="form-check-label">Art, Design, Media</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-7" className="form-check-input" name="s-7" type="checkbox"/>
                                            <label for="s-7" className="form-check-label">Coach & Education</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-8" className="form-check-input" name="s-8" type="checkbox"/>
                                            <label for="s-8" className="form-check-label">Apps Developements</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-9" className="form-check-input" name="s-9" type="checkbox"/>
                                            <label for="s-9" className="form-check-label">IOS Development</label>
                                        </div>
                                    </li>
                                    <li className="col-lg-6 col-md-6 p-0">
                                        <div className="form-check form-check-inline">
                                            <input id="s-10" className="form-check-input" name="s-10" type="checkbox"/>
                                            <label for="s-10" className="form-check-label">Android Development</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="single-tabs-group">
                            <div className="single-tabs-group-header"><h5>Keywords</h5></div>
                            
                            <div className="single-tabs-group-content">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Design, Java, Python, WordPress etc..."/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="filt-buttons-updates">
                    <button type="button" className="btn btn-dark">Clear Filter</button>
                    <button type="button" className="btn btn-primary">Search</button>
                </div>
            </div>
        </div>
    </div>
</div>

<a id="back2Top" className="top-scroll" title="Back to top" href="home-7.html#"><i className="ti-arrow-up"></i></a>

       </>
    );
}

export default Footer;
