import React from 'react';
import { Link } from 'react-router-dom';
function EM_nav_dashboard() {
  return (
   <>
   <div className="collapse" id="MobNav">
					<div className="dashboard-nav">
						<div className="dash-user-blocks pt-5">
							<div className="jbs-grid-usrs-thumb">
								<div className="jbs-grid-yuo">
									<a href="candidate-detail.html"><figure><img src="assets/img/l-12.png" className="img-fluid circle" alt=""/></figure></a>
								</div>
							</div>
							<div className="jbs-grid-usrs-caption mb-3">
								<div className="jbs-kioyer">
									<span className="label text-light theme-bg">05 Openings</span>
								</div>
								<div className="jbs-tiosk">
									<h4 className="jbs-tiosk-title"><a href="candidate-detail.html">Instagram App</a></h4>
									<div className="jbs-tiosk-subtitle"><span><i className="fa-solid fa-location-dot me-2"></i>Canada</span></div>
								</div>
							</div>
						</div>
						<div className="dashboard-inner">
							<ul data-submenu-title="Main Navigation">
								<li className="active"><Link to="../employer/dashboard"><i className="fa-solid fa-gauge-high me-2"></i>User Dashboard</Link></li>
								<li><Link to="../employer/profile"><i className="fa-regular fa-user me-2"></i>User Profile </Link></li>
								<li><Link to="../employer/job"><i className="fa-solid fa-business-time me-2"></i>My Jobs</Link></li>
								<li><Link to="../employer/post-job"><i className="fa-solid fa-pen-ruler me-2"></i>Post Jobs</Link></li>
								<li><Link to="../employer/applicants"><i className="fa-solid fa-user-group me-2"></i>Applicants Jobs</Link></li>
								<li><Link to="../employer/shortlisted-candidates"><i className="fa-solid fa-user-clock me-2"></i>Shortlisted Candidates</Link></li>
								<li><Link to="../employer/update-password"><i className="fa-solid fa-unlock-keyhole me-2"></i>Change Password</Link></li>
								<li><Link to="../employer/logout"><i className="fa-solid fa-power-off me-2"></i>Log Out</Link></li>
							</ul>
						</div>					
					</div>
				</div>
   </>
  );
}

export default EM_nav_dashboard;

       
