import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Dashboard_Nav from './User_dashboard_nav';
function Applied_job() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <div className="dashboard-wrap bg-light">
				<Dashboard_Nav/>
				
				<div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Applied Jobs</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="candidate-applied-jobs.html#">Candidate</a></li>
										<li className="breadcrumb-item text-muted"><a href="candidate-applied-jobs.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="candidate-applied-jobs.html#" className="text-primary">Applied Jobs</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
						
						<div className="row">
							<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<div className="_mp-inner-content elior">
											<div className="_mp-inner-first">
												<div className="search-inline">
													<input type="text" className="form-control" placeholder="Job title, Keywords etc."/>
													<button type="button" className="btn btn-primary">Search</button>
												</div>
											</div>
											<div className="_mp_inner-last">
												<div className="item-shorting-box-right">
													<div className="shorting-by me-2 small">
														<select>
															<option value="0">Short by (Default)</option>
															<option value="1">Short by (Featured)</option>
															<option value="2">Short by (Urgent)</option>
															<option value="3">Short by (Post Date)</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="card-body">
										<div className="row justify-content-start gx-3 gy-4">
									
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-1.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Tripadvisor</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California</span>.<span>07 Apr 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 02 Jun 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-success py-2 px-3 rounded">Approved</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-2.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Pinterest</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Allahabad</span>.<span>2 Apr 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 02 Mar 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-warning py-2 px-3 rounded">Pending</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-3.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Shopify</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>15 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 07 Feb 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-success py-2 px-3 rounded">Approved</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-4.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Jr. Laravel Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Dreezoo</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Liverpool, UK</span>.<span>20 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 02 Jun 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-warning py-2 px-3 rounded">Pending</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-5.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Enternship</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Java & Python Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Photoshop</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California</span>.<span>20 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 20 Oct 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-success py-2 px-3 rounded">Approved</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-6.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. Code Ignetor Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Firefox</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>18 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 02 Jun 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-success py-2 px-3 rounded">Approved</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-7.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Part Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. Magento Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Airbnb</span>.<span><i className="fa-solid fa-location-dot me-1"></i>London, UK</span>.<span>15 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 02 Jun 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-warning py-2 px-3 rounded">Pending</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-8.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">New Shopify Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Snapchat</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Denver, USA</span>.<span>15 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 02 Jun 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-success py-2 px-3 rounded">Approved</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-9.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Front-end Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Dribbble</span>.<span><i className="fa-solid fa-location-dot me-1"></i>New York, USA</span>.<span>7 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 10 Apr 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-warning py-2 px-3 rounded">Pending</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-10.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Technical Content Writer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Skype</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>10 March 2022</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted">Apply 15 Nov 2022</span></div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><span className="text-sm-muted text-light bg-warning py-2 px-3 rounded">Pending</span></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="JavaScript:Void(0);" className="btn btn-md btn-light-danger px-3 me-2"><i className="fa-solid fa-xmark"></i></a>
															<a href="job-detail.html" className="btn btn-md btn-light-primary px-3"><i className="fa-solid fa-eye"></i></a>
														</div>
													</div>
												</div>
											</div>
											
										</div>
									</div>
								</div>
							</div>	
						</div>
	
					</div>
					
					
		
				</div>	
                
                		
				
			</div>
                

</div>
  );
}

export default Applied_job;

       
