import React from 'react';
import { Link } from 'react-router-dom';
import Call_to_action_1 from './Call_to_action1';
import Footer from './Footer';
import Header from './Header';
function Company_page() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <section className="gray-simple">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12">
							<div className="emplr-head-block">
								
								<div className="emplr-head-left">
									<div className="emplr-head-thumb">
										<figure><img src="assets/img/l-1.png" className="img-fluid rounded" alt=""/></figure>
									</div>
									<div className="emplr-head-caption">
										<div className="emplr-head-caption-top">
											<div className="emplr-yior-1"><span className="label text-sm-muted text-success bg-light-success">10 Openings</span></div>
											<div className="emplr-yior-2"><h4 className="emplr-title">Tripadvisor Inc.</h4></div>
											<div className="emplr-yior-3">
												<span><i className="fa-solid fa-building-shield me-1"></i>Software</span>
												<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>
												<span className="text-light opacity-75"><i className="fa-solid fa-star me-1"></i>4.2 (412 Reviews)</span>
											</div>
										</div>
									</div>
								</div>
								<div className="emplr-head-right">
									<button type="button" className="btn btn-primary">Follow Now</button>
									<button type="button" className="btn btn-outline-primary ms-2" data-bs-toggle="modal" data-bs-target="#review"><i className="fa-solid fa-star me-2"></i>Write Review</button>
								</div>
							
							</div>
						</div>
					</div>
				</div>
			</section>


				<section>
				<div className="container">
					<div className="row">

						<div className="col-xl-8 col-lg-8 col-md-12">
							<div className="cdtsr-groups-block">
								
								<div className="single-cdtsr-block">
									<div className="single-cdtsr-header"><h5>About Company</h5></div>
									<div className="single-cdtsr-body">
										<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
									</div>
								</div>
								
								<div className="single-cdtsr-block">
									<div className="single-cdtsr-header"><h5>Our Award</h5></div>
									<div className="single-cdtsr-body">
										<div className="row gx-3 gy-4">
										
											<div className="col-xl-3 col-lg-3 col-md-3">
												<div className="escort-award-wrap">
													<div className="escort-award-thumb">
														<figure><img src="assets/img/award-1.png" className="img-fluid" alt=""/></figure>
													</div>
													<div className="escort-award-caption">
														<h6>FIFFA Award</h6>
														<label>May 2014</label>
													</div>
												</div>
											</div>
											
											<div className="col-xl-3 col-lg-3 col-md-3">
												<div className="escort-award-wrap">
													<div className="escort-award-thumb">
														<figure><img src="assets/img/award-2.png" className="img-fluid" alt=""/></figure>
													</div>
													<div className="escort-award-caption">
														<h6>COMPRA Award</h6>
														<label>Dec 2017</label>
													</div>
												</div>
											</div>
											
											<div className="col-xl-3 col-lg-3 col-md-3">
												<div className="escort-award-wrap">
													<div className="escort-award-thumb">
														<figure><img src="assets/img/award-4.png" className="img-fluid" alt=""/></figure>
													</div>
													<div className="escort-award-caption">
														<h6>ICCPR Award</h6>
														<label>Apr 2022</label>
													</div>
												</div>
											</div>
											
											<div className="col-xl-3 col-lg-3 col-md-3">
												<div className="escort-award-wrap">
													<div className="escort-award-thumb">
														<figure><img src="assets/img/award-3.png" className="img-fluid" alt=""/></figure>
													</div>
													<div className="escort-award-caption">
														<h6>XICAGO Award</h6>
														<label>Jull 2022</label>
													</div>
												</div>
											</div>
										
										</div>
									</div>
								</div>
								
								<div className="single-cdtsr-block">
									<div className="single-cdtsr-header"><h5>Company Services</h5></div>
									<div className="single-cdtsr-body">
										<div className="cndts-all-skills-list">
											<span>WordPress Developements</span>
											<span>Java Developements</span>
											<span>HTML To WordPress</span>
											<span>PHP Developements</span>
											<span>UI/UX Design</span>
											<span>Laravel</span>
											<span>Magento 2.0</span>
										</div>
									</div>
								</div>
								
								<div className="single-cdtsr-block">
									<div className="single-cdtsr-header"><h5>Portfolio</h5></div>
									<div className="single-cdtsr-body">
										<div className="row gx-3 gy-3">
										
											<div className="col-xl-4 col-lg-4 col-md-6 col-6">
												<div className="cndts-prt-block">
													<div className="cndts-prt-thumb">
														<img src="assets/img/blog-1.jpg" className="img-fluid rounded" alt=""/>
													</div>
													<div className="cndts-prt-link"><a href="JavaScript:Void(0);"><i className="fa-solid fa-arrow-up-right-from-square"></i></a></div>
												</div>
											</div>
											
											<div className="col-xl-4 col-lg-4 col-md-6 col-6">
												<div className="cndts-prt-block">
													<div className="cndts-prt-thumb">
														<img src="assets/img/blog-2.jpg" className="img-fluid rounded" alt=""/>
													</div>
													<div className="cndts-prt-link"><a href="JavaScript:Void(0);"><i className="fa-solid fa-arrow-up-right-from-square"></i></a></div>
												</div>
											</div>
											
											<div className="col-xl-4 col-lg-4 col-md-6 col-6">
												<div className="cndts-prt-block">
													<div className="cndts-prt-thumb">
														<img src="assets/img/blog-3.jpg" className="img-fluid rounded" alt=""/>
													</div>
													<div className="cndts-prt-link"><a href="JavaScript:Void(0);"><i className="fa-solid fa-arrow-up-right-from-square"></i></a></div>
												</div>
											</div>
										
										</div>
									</div>
								</div>
								
								<div className="single-cdtsr-block">
									<div className="single-cdtsr-header"><h5>10 Openings</h5></div>
									<div className="single-cdtsr-body">
										<div className="row justify-content-start gx-3 gy-4">
									
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-1.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Tripadvisor</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California</span>.<span>07 Apr 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$85K - 95K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-gray px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-2.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Pinterest</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Allahabad</span>.<span>2 Apr 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$90K - 100K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-gray px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-3.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Product Designer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Shopify</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>15 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$90K - 115K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-gray px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb jbs-verified"><a href="job-detail.html"><figure><img src="assets/img/l-4.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Jr. Laravel Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Dreezoo</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Liverpool, UK</span>.<span>20 March 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$85K - 110K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-gray px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-5.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Enternship</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Java & Python Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Photoshop</span>.<span><i className="fa-solid fa-location-dot me-1"></i>California</span>.<span>20 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$90K - 120K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-gray px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
											<div className="col-xl-12 col-lg-12 col-md-12">
												<div className="jbs-list-box border">
													<div className="jbs-list-head">
														<div className="jbs-list-head-thunner">
															<div className="jbs-list-emp-thumb"><a href="job-detail.html"><figure><img src="assets/img/l-6.png" className="img-fluid" alt=""/></figure></a></div>
															<div className="jbs-list-job-caption">
																<div className="jbs-job-types-wrap"><span className="label text-success bg-light-success">Full Time</span></div>
																<div className="jbs-job-title-wrap"><h4><a href="job-detail.html" className="jbs-job-title">Sr. Code Ignetor Developer</a></h4></div>
																<div className="jbs-job-mrch-lists">
																	<div className="single-mrch-lists">
																		<span>Firefox</span>.<span><i className="fa-solid fa-location-dot me-1"></i>Canada, USA</span>.<span>18 Feb 2023</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="jbs-list-head-middle">
															<div className="elsocrio-jbs"><div className="ilop-tr"><i className="fa-solid fa-sack-dollar"></i></div><h5 className="jbs-list-pack">$80K - 90K<span className="patype">\PA</span></h5></div>
														</div>
														<div className="jbs-list-head-last">
															<a href="job-detail.html" className="btn btn-md btn-gray px-3 me-2">View Detail</a>
															<a href="JavaScript:Void(0);" className="btn btn-md btn-primary px-3">Quick Apply</a>
														</div>
													</div>
												</div>
											</div>
											
										</div>
									</div>
								</div>
								
								<div className="single-cdtsr-block">
									<div className="single-cdtsr-header"><h5>412 Reviews</h5></div>
									<div className="single-cdtsr-body">
										
										<div className="singleReviews border-bottom py-4">
											<div className="singlerev d-flex align-items-start justify-content-start gap-3">
												<div className="singlratebox bg-light rounded-3">
													<div className="px-3 py-4 text-center">
														<h3 className="m-0">4.7</h3>
														<div className="d-flex align-items-center justify-content-center gap-1 text-xs">
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star-half text-warning"></i>
														</div>
													</div>
												</div>
												<div className="reviewsCaption">
													<div className="reviewsHeader mb-3">
														<h4>Very professional and Smart Company</h4>
														<div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Freelance VizRT</a>(Current Employee).</div>
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Washington, DC</a>.</div>
															<div className="text-muted text-md">November 15 2023</div>
														</div>
													</div>
													
													<div className="reviewsDescription">
														<p>
															<span className="d-block">What is the best part of working at the company?</span>
															<span>Communication between the operation employees is top notch. Everyone is on the same page. Always plan ahead for the both live and pre recorded events.</span>
														</p>
														<p>
															<span className="d-block">What is the most stressful part about working at the company?</span>
															<span>Relationship between employees is somewhat neutral unless there are sides and that’s when it gets awkward</span>
														</p>
														<p>
															<span className="d-block">What is the work environment and culture like at the company?</span>
															<span>Mostly professional, everyone keeps to themselves.</span>
														</p>
														<p>
															<span className="d-block">What is a typical day like for you at the company?</span>
															<span>My shift always start a couple of hours before going live and during this time I have to go through the rundown make sure everything is in place.</span>
														</p>
													</div>
													
													<div className="d-block mt-4">
														<div className="text-muted text-md mb-1">Was this review helpful?</div>
														<div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
															<div className="d-first">
																<div className="btn-group" role="group" aria-label="Basic outlined example">
																	<button type="button" className="btn btn-md btn-outline-primary">Yes</button>
																	<button type="button" className="btn btn-md btn-outline-primary">No</button>
																</div>
															</div>
															<div className="d-last d-flex align-items-center justify-content-end gap-3">
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-regular fa-flag me-2"></i>Report</a>
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-solid fa-up-right-from-square me-2"></i>Share</a>
															</div>
														</div>
													</div>
											
												</div>
											</div>

										</div>
										
										<div className="singleReviews border-bottom py-4">
											<div className="singlerev d-flex align-items-start justify-content-start gap-3">
												<div className="singlratebox bg-light rounded-3">
													<div className="px-3 py-4 text-center">
														<h3 className="m-0">4.4</h3>
														<div className="d-flex align-items-center justify-content-center gap-1 text-xs">
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star-half text-warning"></i>
														</div>
													</div>
												</div>
												<div className="reviewsCaption">
													<div className="reviewsHeader mb-3">
														<h4>Superb Company & Services</h4>
														<div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Freelance VizRT</a>(Current Employee).</div>
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Washington, DC</a>.</div>
															<div className="text-muted text-md">July 10 2023</div>
														</div>
													</div>
													
													<div className="reviewsDescription">
														<p>
															<span className="d-block">What is the best part of working at the company?</span>
															<span>Communication between the operation employees is top notch. Everyone is on the same page. Always plan ahead for the both live and pre recorded events.</span>
														</p>
														<p>
															<span className="d-block">What is the most stressful part about working at the company?</span>
															<span>Relationship between employees is somewhat neutral unless there are sides and that’s when it gets awkward</span>
														</p>
														<p>
															<span className="d-block">What is the work environment and culture like at the company?</span>
															<span>Mostly professional, everyone keeps to themselves.</span>
														</p>
														<p>
															<span className="d-block">What is a typical day like for you at the company?</span>
															<span>My shift always start a couple of hours before going live and during this time I have to go through the rundown make sure everything is in place.</span>
														</p>
													</div>
													
													<div className="d-block mt-4">
														<div className="text-muted text-md mb-1">Was this review helpful?</div>
														<div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
															<div className="d-first">
																<div className="btn-group" role="group" aria-label="Basic outlined example">
																	<button type="button" className="btn btn-md btn-outline-primary">Yes</button>
																	<button type="button" className="btn btn-md btn-outline-primary">No</button>
																</div>
															</div>
															<div className="d-last d-flex align-items-center justify-content-end gap-3">
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-regular fa-flag me-2"></i>Report</a>
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-solid fa-up-right-from-square me-2"></i>Share</a>
															</div>
														</div>
													</div>
											
												</div>
											</div>

										</div>
										
										<div className="singleReviews border-bottom py-4">
											<div className="singlerev d-flex align-items-start justify-content-start gap-3">
												<div className="singlratebox bg-light rounded-3">
													<div className="px-3 py-4 text-center">
														<h3 className="m-0">2.3</h3>
														<div className="d-flex align-items-center justify-content-center gap-1 text-xs">
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-regular fa-star text-warning"></i>
															<i className="fa-regular fa-star text-warning"></i>
															<i className="fa-regular fa-star text-warning"></i>
														</div>
													</div>
												</div>
												<div className="reviewsCaption">
													<div className="reviewsHeader mb-3">
														<h4>Very Bad Management</h4>
														<div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Freelance VizRT</a>(Current Employee).</div>
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Washington, DC</a>.</div>
															<div className="text-muted text-md">August 20 2023</div>
														</div>
													</div>
													
													<div className="reviewsDescription">
														<p>
															<span className="d-block">What is the best part of working at the company?</span>
															<span>Communication between the operation employees is top notch. Everyone is on the same page. Always plan ahead for the both live and pre recorded events.</span>
														</p>
														<p>
															<span className="d-block">What is the most stressful part about working at the company?</span>
															<span>Relationship between employees is somewhat neutral unless there are sides and that’s when it gets awkward</span>
														</p>
														<p>
															<span className="d-block">What is the work environment and culture like at the company?</span>
															<span>Mostly professional, everyone keeps to themselves.</span>
														</p>
														<p>
															<span className="d-block">What is a typical day like for you at the company?</span>
															<span>My shift always start a couple of hours before going live and during this time I have to go through the rundown make sure everything is in place.</span>
														</p>
													</div>
													
													<div className="d-block mt-4">
														<div className="text-muted text-md mb-1">Was this review helpful?</div>
														<div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
															<div className="d-first">
																<div className="btn-group" role="group" aria-label="Basic outlined example">
																	<button type="button" className="btn btn-md btn-outline-primary">Yes</button>
																	<button type="button" className="btn btn-md btn-outline-primary">No</button>
																</div>
															</div>
															<div className="d-last d-flex align-items-center justify-content-end gap-3">
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-regular fa-flag me-2"></i>Report</a>
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-solid fa-up-right-from-square me-2"></i>Share</a>
															</div>
														</div>
													</div>
											
												</div>
											</div>

										</div>
										
										<div className="singleReviews border-bottom py-4">
											<div className="singlerev d-flex align-items-start justify-content-start gap-3">
												<div className="singlratebox bg-light rounded-3">
													<div className="px-3 py-4 text-center">
														<h3 className="m-0">4.9</h3>
														<div className="d-flex align-items-center justify-content-center gap-1 text-xs">
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star text-warning"></i>
															<i className="fa-solid fa-star-half text-warning"></i>
														</div>
													</div>
												</div>
												<div className="reviewsCaption">
													<div className="reviewsHeader mb-3">
														<h4>One of The Best Company in Canada</h4>
														<div className="d-flex align-items-center justify-content-start flex-wrap gap-2">
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Freelance VizRT</a>(Current Employee).</div>
															<div className="text-muted text-md"><a href="employer-detail.html#" className="text-primary">Washington, DC</a>.</div>
															<div className="text-muted text-md">July 27 2022</div>
														</div>
													</div>
													
													<div className="reviewsDescription">
														<p>
															<span className="d-block">What is the best part of working at the company?</span>
															<span>Communication between the operation employees is top notch. Everyone is on the same page. Always plan ahead for the both live and pre recorded events.</span>
														</p>
														<p>
															<span className="d-block">What is the most stressful part about working at the company?</span>
															<span>Relationship between employees is somewhat neutral unless there are sides and that’s when it gets awkward</span>
														</p>
														<p>
															<span className="d-block">What is the work environment and culture like at the company?</span>
															<span>Mostly professional, everyone keeps to themselves.</span>
														</p>
														<p>
															<span className="d-block">What is a typical day like for you at the company?</span>
															<span>My shift always start a couple of hours before going live and during this time I have to go through the rundown make sure everything is in place.</span>
														</p>
													</div>
													
													<div className="d-block mt-4">
														<div className="text-muted text-md mb-1">Was this review helpful?</div>
														<div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
															<div className="d-first">
																<div className="btn-group" role="group" aria-label="Basic outlined example">
																	<button type="button" className="btn btn-md btn-outline-primary">Yes</button>
																	<button type="button" className="btn btn-md btn-outline-primary">No</button>
																</div>
															</div>
															<div className="d-last d-flex align-items-center justify-content-end gap-3">
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-regular fa-flag me-2"></i>Report</a>
																<a href="employer-detail.html#" className="text-dark fw-medium"><i className="fa-solid fa-up-right-from-square me-2"></i>Share</a>
															</div>
														</div>
													</div>
											
												</div>
											</div>

										</div>
										
									</div>
									
									<div className="pagfooter mx-auto mb-3">
										<nav aria-label="Page navigation example" className="justify-content-center align-items-center">
											  <ul className="pagination">
												<li className="page-item">
												  <a className="page-link" href="JavaScript:Void(0);" aria-label="Previous">
													<span aria-hidden="true">«</span>
												  </a>
												</li>
												<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">1</a></li>
												<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">2</a></li>
												<li className="page-item active"><a className="page-link" href="JavaScript:Void(0);">3</a></li>
												<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">4</a></li>
												<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">5</a></li>
												<li className="page-item"><a className="page-link" href="JavaScript:Void(0);">6</a></li>
												<li className="page-item">
												  <a className="page-link" href="JavaScript:Void(0);" aria-label="Next">
													<span aria-hidden="true">»</span>
												  </a>
												</li>
											  </ul>
										</nav>
									</div>
									
								</div>
							
							</div>
						</div>
						
						<div className="col-xl-4 col-lg-4 col-md-12">

							<div className="eflorio-wrap-block mb-4">
								<div className="eflorio-wrap-group">
									<div className="eflorio-wrap-body">
										<div className="eflorio-list-groups">
										
											<div className="single-eflorio-list">
												<div className="eflorio-list-icons"><i className="fa-solid fa-envelope-circle-check text-primary"></i></div>
												<div className="eflorio-list-captions">
													<label>Email Address</label>
													<h6>themezhub@gmail.com</h6>
												</div>
											</div>
											
											<div className="single-eflorio-list">
												<div className="eflorio-list-icons"><i className="fa-solid fa-phone-volume text-primary"></i></div>
												<div className="eflorio-list-captions">
													<label>Contact No.</label>
													<h6>9450 542 6325</h6>
												</div>
											</div>
											
											<div className="single-eflorio-list">
												<div className="eflorio-list-icons"><i className="fa-solid fa-layer-group text-primary"></i></div>
												<div className="eflorio-list-captions">
													<label>Category</label>
													<h6>Applications</h6>
												</div>
											</div>
											
											<div className="single-eflorio-list">
												<div className="eflorio-list-icons"><i className="fa-solid fa-user-group text-primary"></i></div>
												<div className="eflorio-list-captions">
													<label>Company Size</label>
													<h6>1000-1500</h6>
												</div>
											</div>
											
											<div className="single-eflorio-list">
												<div className="eflorio-list-icons"><i className="fa-solid fa-map-location-dot text-primary"></i></div>
												<div className="eflorio-list-captions">
													<label>Location</label>
													<h6>California, USA</h6>
												</div>
											</div>
											
											<div className="single-eflorio-list">
												<div className="eflorio-list-icons"><i className="fa-solid fa-building-circle-check text-primary"></i></div>
												<div className="eflorio-list-captions">
													<label>Established</label>
													<h6>Oct 2010</h6>
												</div>
											</div>
										
										</div>
									</div>
									<div className="eflorio-wrap-footer">
										<div className="eflorio-footer-body">
											<button type="button" className="btn btn-primary fw-medium full-width">View Website</button>
										</div>
									</div>
								</div>
							</div>
							
							<div className="sidefr-usr-block">
								<div className="cndts-share-block">
									<div className="cndts-share-title">
										<h5>Share Profile</h5>
									</div>
									<div className="cndts-share-list">
										<ul>
											<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-facebook-f"></i></a></li>
											<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-twitter"></i></a></li>
											<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-google-plus-g"></i></a></li>
											<li><a href="JavaScript:Void(0);"><i className="fa-brands fa-instagram"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
                	
			</section>

            <Call_to_action_1/>
                
            <Footer/>
  		

</div>
  );
}

export default Company_page;

       
