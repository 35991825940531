import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import EM_nav_dashboard from './Dashboard_nav';
function EM_Signup() {
  return (
    <div id="main-wrapper">
		
    <Header/>

<div className="clearfix"></div>



<section className="gray-simple">
    <div className="container">
        <div className="row justify-content-center">
        
            <div className="col-xl-6 col-lg-8 col-md-12">
                <div className="vesh-detail-bloc">
                    <div className="vesh-detail-bloc-body pt-3">
                        <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <button className="nav-link active" id="signin-tab" data-bs-toggle="pill" data-bs-target="#signin" type="button" role="tab" aria-controls="signin" aria-selected="true">Login Account</button>
                            </li>
                            <li className="nav-item">
                            <button className="nav-link" id="register-tab" data-bs-toggle="pill" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="false" tabindex="-1">Create Account</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="signin" role="tabpanel" aria-labelledby="signin-tab" tabindex="0">
                                <div className="row gx-3 gy-4">
                                    <div className="modal-login-form">
                                        <form>
                                        
                                            <div className="form-floating mb-4">
                                                <input type="email" className="form-control" placeholder="name@example.com"/>
                                                <label>User Name</label>
                                            </div>
                                            
                                            <div className="form-floating mb-4">
                                                <input type="password" className="form-control" placeholder="Password"/>
                                                <label>Password</label>
                                            </div>
                                            
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Log In</button>
                                            </div>
                                            
                                            <div className="modal-flex-item mb-3">
                                                <div className="modal-flex-first">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" id="savepassword1" value="option1"/>
                                                        <label className="form-check-label" for="savepassword1">Save Password</label>
                                                    </div>
                                                </div>
                                                <div className="modal-flex-last">
                                                    <a href="JavaScript:Void(0);">Forget Password?</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                  
                                </div>
                            </div>
                            
                            <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab" tabindex="0">
                                <div className="row gx-3 gy-4">
                                    <div className="modal-login-form">
                                        <form>
                                        
                                            <div className="form-floating mb-4">
                                                <input type="email" className="form-control" placeholder="Your Name"/>
                                                <label>Full Name</label>
                                            </div>
                                            <div className="form-floating mb-4">
                                                <input type="email" className="form-control" placeholder="name@example.com"/>
                                                <label>Email</label>
                                            </div>
                                            <div className="form-floating mb-4">
                                                <input type="email" className="form-control" placeholder="example123"/>
                                                <label>User ID</label>
                                            </div>
                                            <div className="form-floating mb-4">
                                                <input type="password" className="form-control" placeholder="Password"/>
                                                <label>Password</label>
                                            </div>
                                            <div className="form-floating mb-4">
                                                <input type="password" className="form-control" placeholder="Password"/>
                                                <label>Confirm Password</label>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Create An Account</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>	
</section>


            
<Footer/>

</div>
  );
}

export default EM_Signup;

       
