import React from 'react';
import { Link } from 'react-router-dom';
function Header() {
  return (
       <>
       	
    <div className="header header-transparent dark">
        <div className="container">
            <nav id="navigation" className="navigation navigation-landscape">
                <div className="nav-header">
                    <a className="nav-brand" href="home-7.html#">
                        <img src="assets/img/logo.png" className="logo" alt=""/>
                    </a>
                    <div className="nav-toggle"></div>
                    <div className="mobile_nav">
                        <ul>
                            <li className="list-buttons">
                                <a href="JavaScript:Void(0);" data-bs-toggle="modal" data-bs-target="#login"><i className="fas fa-sign-in-alt me-2"></i>Log In</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="nav-menus-wrapper">
                    <ul className="nav-menu">
                    
                        <li className="active"><Link to="/">Home<span className="submenu-indicator"></span></Link>
                            
                        </li>
                        
                        <li><Link to="../dashboard">For Candidate<span className="submenu-indicator"></span></Link>
                            <ul className="nav-dropdown nav-submenu">
                                <li><Link to="../dashboard">My Profile<span className="submenu-indicator"></span></Link>
                                </li>
                                <li><Link to="../job">Search Job<span className="submenu-indicator"></span></Link>
                                </li>
                                
                            </ul>
                        </li>
                        
                        <li><Link to="../employer/dashboard">For Employer<span className="submenu-indicator"></span></Link>
                            
                        </li>
                        
                       
                        
                        <li><a href="">Help</a></li>
                        
                    </ul>
                    
                    <ul className="nav-menu nav-menu-social align-to-right">
                        <li>
                            <Link to="../signup" ><i className="fas fa-sign-in-alt me-2"></i>Sign In</Link>
                        </li>
                        <li className="list-buttons ms-2">
                            <Link to="../resume"><i className="fa-solid fa-cloud-arrow-up me-2"></i>Upload Resume</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
       </>
    );
}

export default Header;
