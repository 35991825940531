import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Dashboard_Nav from './User_dashboard_nav';
function Profile() {
  return (
    <div id="main-wrapper">
		
    	<Header/>

    <div className="clearfix"></div>
   
    <div className="dashboard-wrap bg-light">
				<Dashboard_Nav/>
				
				<div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-4">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="mb-1 fs-3 fw-medium">Candidate Profile</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="candidate-profile.html#">Candidate</a></li>
										<li className="breadcrumb-item text-muted"><a href="candidate-profile.html#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="candidate-profile.html#" className="text-primary">Candidate Profile</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="dashboard-widg-bar d-block">
					
						<div className="dashboard-profle-wrapper mb-4">
							<div className="dash-prf-start">
								<div className="dash-prf-start-upper">
									<div className="dash-prf-start-thumb">
										<figure><img src="assets/img/team-1.jpg" className="img-fluid circle" alt=""/></figure>
									</div>
								</div>
								<div className="dash-prf-start-bottom">
									<div className="upload-btn-wrapper small">
										<button className="btn">Change Profile</button>
										<input type="file" name="myfile"/>
									</div>
								</div>
							</div>
							<div className="dash-prf-end">
								<div className="dash-prfs-caption">
									<div className="dash-prfs-title">
										<h4>R. Daniel Markduke</h4>	
									</div>
									<div className="dash-prfs-subtitle">
										<div className="jbs-job-mrch-lists">
											<div className="single-mrch-lists">
												<span>Sr. Web Designer</span>.<span><i className="fa-solid fa-location-dot me-1"></i>London</span>
											</div>
										</div>
									</div>
									<div className="jbs-grid-job-edrs-group mt-1">
										<span>HTML</span>
										<span>CSS3</span>
										<span>Bootstrap</span>
										<span>Redux</span>
									</div>
								</div>
								<div className="dash-prf-caption-end">
									<div className="dash-prf-infos">
										<div className="single-dash-prf-infos">
											<div className="single-dash-prf-infos-icons"><i className="fa-solid fa-envelope-open-text"></i></div>
											<div className="single-dash-prf-infos-caption">
												<p className="text-sm-muted mb-0">Email</p>
												<h5>Themezhub@gmail.com</h5>
											</div>
										</div>
										<div className="single-dash-prf-infos">
											<div className="single-dash-prf-infos-icons"><i className="fa-solid fa-phone-volume"></i></div>
											<div className="single-dash-prf-infos-caption">
												<p className="text-sm-muted mb-0">Call</p>
												<h5>+91 256 356 8547</h5>
											</div>
										</div>
										<div className="single-dash-prf-infos">
											<div className="single-dash-prf-infos-icons"><i className="fa-solid fa-briefcase"></i></div>
											<div className="single-dash-prf-infos-caption">
												<p className="text-sm-muted mb-0">Exp.</p>
												<h5>5+ Years</h5>
											</div>
										</div>
									</div>
									<div className="dash-prf-completion">
										<p className="text-sm-muted">Profile Completed</p>
										<div className="progress" role="progressbar" aria-label="Example Success with label" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                        <div className="progress-bar bg-success" style={{ width: '75%', color: 'white', textAlign: 'center' }}>
      75%
    </div>										</div>
									</div>
								</div>
							</div>
						
						</div>
						
						<div className="card">
							<div className="card-header">
								<h4>Basic Detail</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
									
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Your Name</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Job Title</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Age</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Education</label>
												<div className="select-ops">
													<select>
														<option value="1">High School</option>
														<option value="2">Intermidiate</option>
														<option value="3">Bachelore Degree</option>
														<option value="4">Master Degree</option>
														<option value="5">Post Graduate</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Experience</label>
												<div className="select-ops">
													<select>
														<option value="1">Fresher</option>
														<option value="2">1+ Year</option>
														<option value="3">2+ Year</option>
														<option value="4">4+ Year</option>
														<option value="5">5+ Year</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Language</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="form-group">
												<label>About Info</label>
												<textarea className="form-control ht-80"></textarea>
											</div>
										</div>
										
									</div> 
								</form>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header">
								<h4>Contact Detail</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
									
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Your Email</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Phone no.</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Temporary Address</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Address</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Address 2</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Country</label>
												<div className="select-ops">
													<select>
														<option value="1">India</option>
														<option value="2">United State</option>
														<option value="3">United kingdom</option>
														<option value="4">Austrailia</option>
														<option value="5">Russia</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>State/City</label>
												<div className="select-ops">
													<select>
														<option value="1">California</option>
														<option value="2">Denver</option>
														<option value="3">New York</option>
														<option value="4">Canada</option>
														<option value="5">Poland</option>
													</select>
												</div>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Zip Code</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Latitude</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>longitude</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
									</div> 
								</form>
							</div>
						</div>
						
						<div className="card">
							<div className="card-header">
								<h4>Social Login</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="row">
									
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Facebook</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Twitter</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Instagram</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Linked In</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Twitter</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
										<div className="col-xl-6 col-lg-6 col-md-12">
											<div className="form-group">
												<label>Google Plus</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										
									</div> 
								</form>
							</div>
						</div>						
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<button type="submit" className="btn ft--medium btn-primary">Save Profile</button>
							</div>	
						</div>
	
					</div>
					
					
		
				</div>		
                
                		
				
			</div>
                

</div>
  );
}

export default Profile;

       
